import PropTypes from "prop-types";

import React, {useState, useEffect} from "react";

import axios from 'axios'

import '../../index.css';

import { withRouter, Link, useParams } from "react-router-dom";

import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label, Button } from "reactstrap";

import logoGif from "../../assets/uploads/logo_gif.png"

import ReCAPTCHA from "react-google-recaptcha";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'

// Attachments
import termsPDF from "../../assets/attachments/Terms.pdf";
import popiPDF from "../../assets/attachments/Nessfin_POPI_AGREEMENT.pdf"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = props => {

  let { company } = useParams()

  const[name, setName] =  React.useState('');
  const[last, setLast] =  React.useState('');
  const[email, setEmail] =  React.useState('');
  const[cPassword, setCPassword] =  React.useState('');
  const[password, setPassword] =  React.useState('');
  const[cell, setCell] =  React.useState('');

  const[terms, setTerms] =  React.useState(false);
  const[popi, setPopi] =  React.useState(false);

  const [reCaptcha,setReCaptcha] = useState('')

  
  const[userOptionSelected, setUserOptionSelected] =  React.useState(true);
  
  const[showFinalMessage, setShowFinalMessage] =  React.useState(false);
  const[showRegButton, setShowRegButton] =  React.useState(true);
  const[showPage, setShowPage] =  React.useState(false);
  const[referralCode, setReferralCode] =  React.useState(false);

  React.useEffect(() =>{

    runCompanyCheck()

  },[])

  const runCompanyCheck = async() => {
    try{

    
      const companyObj = {
        company: company
      }
      console.log("we need to show page")
      const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-company-referral",companyObj).then(async (res)=>{
        if(res.data.status === 200)
        {
          console.log("we need to show page")
          setShowPage(true)
          setReferralCode(res.data.data.userid)
        }else{
          return window.location.href="/404"
        }
      });
    }catch(err)
    {
      return window.location.href="/404"
    }
  }


  const updateName = async (name) => {
    setName(name);
  }

  const updateLast = async (last) => {
    setLast(last);
  }

  const updateEmail = async (email) => {
    setEmail(email);
  }

  const updatePassword = async (password) => {
    setPassword(password);
  }


  const updateCPassword = async (password) => {
    setCPassword(password);
  }

  const updateTerms = async (terms) => {
    setTerms(terms);
  }

  const updatePopi = async (popi) => {
    setPopi(popi);
  }

  const updateCell = async (cell) => {
    setCell(cell)
  }

  const changeUserType = async (type) => {
    console.log(type)
    setUserOptionSelected(type)
  }

  function onChangeRecaptcha(value)
  {
    setReCaptcha(value);
  }

  const subLoginDetails = async () => {

    setShowRegButton(false)

    console.log('clicked submit ', userOptionSelected)

    var showError = false;
    var errorMessage = '';
    var userType = '0'

    if(!userOptionSelected)
    {
      userType = '1';
      console.log("set usertype ", userType,   userOptionSelected)
    }
    // return false;

    // const userType = req.body.usertype;
    if(name == '' )
    {
      showError = true;
      errorMessage = 'Please enter First Name'
    }

    if(last == '' && userOptionSelected )
    {
      showError = true;
      errorMessage = 'Please enter Last Name'
    }

    if(!terms )
    {
      showError = true;
      errorMessage = 'Terms And Conditions is not checked'
    }

    if(!popi )
    {
      showError = true;
      errorMessage = 'POPI is not checked'
    }

    if(cell == '' )
    {
      showError = true;
      errorMessage = 'Please enter Mobile Number'
    }

    if(email == '' )
    {
      showError = true;
      errorMessage = 'Please enter Email'
    }

    if(password == '' )
    {
      showError = true;
      errorMessage = 'Please enter valid Password'
    }


    if(cPassword == '' )
    {
      showError = true;
      errorMessage = 'Please enter Valid Confirm Password'
    }

    if(reCaptcha == '' || reCaptcha == null)
    {
      showError = true;
      errorMessage = 'Google Recaptcha required'
    }

    if(showError)
    {
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });

        return false;
    }


    var userObj = {
      name: name,
      last: last,
      ucode: referralCode,
      terms: terms,
      popi: popi,
      cell: cell,
      email: email,
      usertype: userType,
      password: password,
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register-new-user`,userObj).then(async (res)=>{
      console.log(res.data)
      if(res.data.status == 200)
      {
        toast.success("User Created Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

          setShowFinalMessage(true)
  
      }else{

        setShowRegButton(true)
        toast.error("Unable to create user at the moment", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
    })

  }


  return (
    <React.Fragment>
      <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div className="website-main-bg rounded-xl shadow h-screen c1-full-height-mobile mb-5">
        {showPage ?
        <div className="px-6 md:h-full text-white-800">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >

              <img
                src={logoGif}
                className="w-full"
                alt="Sample image"
              />
            </div>

            <div style={{padding: "20px", borderRadius: "15px"}} className="website-light-dark-bg xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">

              {!showFinalMessage ? 
              <form>

              <div className="mb-4">
                <h1 className="text-white text-center text-4xl">Become a member</h1>

                <h1 className="text-white text-center">In Order to become a member, please fill in the fields required below.</h1>
              </div>

              <div className="mb-6">
                <div className="grid grid-cols-3 gap-2 md:grid-cols-3 lg:grid-cols-3 text-center">
                  <div onClick={(e) => {changeUserType(true)}} className={userOptionSelected? "form-no-background" : ''}>
                  <FontAwesomeIcon  icon={faUser} size="xl"  /><br />
                    User
                  </div>
                  <div>Or</div>
                  <div onClick={(e) => {changeUserType(false)}} className={!userOptionSelected? "form-no-background" : ''}>
                  <FontAwesomeIcon  icon={faBuilding} size="xl"  /><br />
                    Company
                    </div>
                </div>
              </div>
   
                <div className="mb-6">
                  <input
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder={userOptionSelected? "Name" : "Company Name" }
                    onChange={(e) => {updateName(e.target.value)}}
                   
                    value={name}
                  />
                </div>

                {userOptionSelected? 
                <div className="mb-6">
                  <input
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Surname"
                   
                    onChange={(e) => {updateLast(e.target.value)}}
                    value={last}
                  />
                </div>
                 : "" }

                <div className="mb-6">
                  <input
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder={userOptionSelected? "Cell" : "Company Cell" }
                   
                    onChange={(e) => {updateCell(e.target.value)}}
                    value={cell}
                  />
                </div>

                <div className="mb-6">
                  <input
                    type="email"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder={userOptionSelected? "Email" : "Company Email" }
                   
                    onChange={(e) => {updateEmail(e.target.value)}}
                    value={email}
                  />
                </div>

                <div className="mb-6">
                  <input
                    type="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Password"
                   
                    onChange={(e) => {updatePassword(e.target.value)}}
                    value={password}
                  />
                </div>

                <div className="mb-6">
                  <input
                    type="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Confirm Password"
                 
                    onChange={(e) => {updateCPassword(e.target.value)}}
                    value={cPassword}
                  />
                </div>

                <div className="mb-6">
                <ReCAPTCHA
                          sitekey="6LekLJglAAAAAKxb4v80gAe1cChiNbgGnKN56dPB"
                          onChange={onChangeRecaptcha}
                        />
                </div>

                <div className="mb-6">

                  
                <input
                      style={{width: "15px"}}
                      name="terms"
                      autoComplete="new-password"
                      className="form-control fwv-input-control mt-1 ml-2"

                      type="checkbox"
                      checked={terms}
                      onChange={(e) => {updateTerms(e.target.checked)}}
                                          
                    />

                  <label className="loginLabel ml-2">
                    By registering you agree to Nessfin{" "}
                    <a  rel="noreferrer"  href={termsPDF} target="_blank" className="primary-color"> Terms
                    </a>
                   </label>

                </div>

                <div className="mb-6">

                    <input
                      style={{width: "15px"}}
                      name="terms"
                      autoComplete="new-password"
                      className="form-control fwv-input-control mt-1 ml-2"

                      type="checkbox"
                      checked={popi}
                      onChange={(e) => {updatePopi(e.target.checked)}}
                                          
                    />
                  <label className="loginLabel ml-2">
                    POPI{" "}
                    <a  rel="noreferrer"  href={popiPDF} target="_blank" className="primary-color">
                      Agreement
                    </a>
                  </label>

                </div>

                <div className="text-center lg:text-left">
                  {showRegButton ?
                  <button type="button" className="website-primary-btn " onClick={(e) => {subLoginDetails(e)}}>
                    Register
                  </button>
                  : ''}
                  
                  <p className="text-sm font-semibold mt-2 pt-1 mb-0 text-white">Already have an account?<a href="/login" className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out">Login</a></p>
                </div>

              </form>
              : 
                <div>
                  <h1 className="border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background">Thank you for registering, we have sent a confirmation email to the registered email.</h1>
                </div>
              
              }
            </div>
            
          </div>
        </div>
      : ''}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Register);

Register.propTypes = {
    history: PropTypes.object,
  };

