import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'

function Deposit() {

    const [modalOTP, setModalOTP] = React.useState({otp: '', from: '', amount: '', type: 'deposit', reference: ''});

    const[modalDisplay, setModalDisplay] =  React.useState(false);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[canContinue, setCanContinue] =  React.useState(true);

    React.useEffect(()=>{

        if (auth != null) {
            if (!auth) {
              window.location.href="/login"
            } else if(auth && user)
            {
                console.log("Logged in ", auth, user);
                setUserid(user.user.userid)
            }
          }
    

    },[auth])

    const openTransactionModal = () => {

        setModalDisplay(true)
        console.log("Open the modal")
    }

    const closeModal = () => {
        setModalDisplay(false)
    }

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
    }

    const handleAccept = async () => {


        console.log("submitted the following ", modalOTP)

        if(modalOTP['amount'] <= 0)
        {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(modalOTP['reference'] === '')
        {
            toast.error('Please Enter Valid Reference or TXID', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(canContinue)
        {
            setCanContinue(false)
            var otpObj = {
                userid: userid,
                otp: modalOTP['otp']
            }
            
            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                    
                if(res.data.status !== 200)
                {
                    
                    toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });

                        await sendOTP();

                        setCanContinue(true)

                    return false;
                }else{
                    return true;
                }
            });

            if(!checkOtp)
                return false;

            const depositObject = {
                userid: userid,
                txid: modalOTP['reference'],
                amount: modalOTP['amount'],
                tran_cat: '1',
                currency: currencyConvert[modalOTP['from']],
                type: "Adding Deposit"
            }
            
            
            var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction",depositObject).then(async res =>{

                console.log("got the deposit response ", res.data)

                if(res.data.status == 200)
                {
                    toast.success('Deposit Created Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });

                        setTimeout(() => {window.location.reload(); }, 2000);

                       
                }else{
                    toast.error('Unable To Create Deposit', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });

                        setCanContinue(true)
                }

            });
        }

        // setModalDisplay(false)
      }

      const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
            console.log(res.data)
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }
  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

        {modalDisplay ? 
            <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} title="Deposit" />
            : ''
        }
        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Deposit</h1>

        <div className="container-md mx-auto px-4">

       
            <div className="container-md px-4 primary-br-2">

                <div className="">
                    <ol type="1">
                        <li>Deposits can be made in the following currencies:
                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                <li style={{listStyleType:"disc"}}>ZAR</li>
                                <li style={{listStyleType:"disc"}}>USDT</li>
                                <li style={{listStyleType:"disc"}}>Bitcoin</li>
                            </ul>
                        </li>
                        <li>For any ZAR deposits, please contact info@nessfinassets.io:
                            {/* <ul className='ml-3' style={{listStyleType:"disc"}}>
                                <li style={{listStyleType:"disc"}}>Account Holder: Nessfin</li>
                                <li style={{listStyleType:"disc"}}>Bank Name: FNB</li>
                                <li style={{listStyleType:"disc"}}>Account Type: Gold Business Account</li>
                                <li style={{listStyleType:"disc"}}>Account Number: 63029823332</li>
                                <li style={{listStyleType:"disc"}}>Branch Code: 200710</li>
                                <li style={{listStyleType:"disc"}}>Branch Name: Wellington</li>
                                <li style={{listStyleType:"disc"}}>Swift code: FIRNZAJJ</li>
                            </ul> */}
              
                            {/* OR
                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                <li style={{listStyleType:"disc"}}>Account Holder: Nessfin</li>
                                <li style={{listStyleType:"disc"}}>Bank Name: Mercantile Bank</li>
                                <li style={{listStyleType:"disc"}}>Account Type: Current Account</li>
                                <li style={{listStyleType:"disc"}}>Account Number: 1051271495</li>
                                <li style={{listStyleType:"disc"}}>Branch Code: 450105</li>
                                <li style={{listStyleType:"disc"}}>Branch Name: Sandton</li>
                                <li style={{listStyleType:"disc"}}>Swift Code: CABLZAJJ</li>
                            </ul> */}
                        </li>
                        <li>For USDT payments please send an email request to info@nessfinassets.io to obtain wallet address</li>
                        <li>ZAR deposits will remain pending until amount is reflected in the bank account of Nessfin.</li>
                        <li>Approvals may take up to 24 hours</li>

                        <li>There are no deposit fees applicable and “over the counter” have fixed fees structures</li>
                        
    
                    </ol>  

                </div>

                <div className='m-auto text-center'><button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center'>Deposit Now</button></div>
                
            </div>

  

        </div>
       

    
    </div>
  )
}

export default Deposit