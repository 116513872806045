import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

// import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import Terms from "../../assets/attachments/Terms.pdf"

import PropTypes from 'prop-types'


function ProductConfirmModal({closeModal, displayModal, modalOTP, handleAccept, productid, duration, showSpinner}) {

    const [otp, setOTP] = React.useState('');
    const [isAccept, setIsAccept] = React.useState(false);

    const [vaultStartDate, setVaultStartDate] = React.useState('');
    const [vaultEndDate, setVaultEndDate] = React.useState('');

    const{auth,user} = React.useContext(UserContext)

    const handleTC = async (accept) => {
      console.log("submitted the following ", accept)
      modalOTP['tcAccept'] = accept;
      setIsAccept(accept);

    }

    React.useEffect(()=>{
    
      if(auth && user)
      {
        if(productid == '15')
        {
          getDatesVault(productid, duration)
        }else{
  
          getDatesProduct(productid, duration)
        }

       // if(productid == '18' || productid == '19' || productid == '20')
          // {
          //   getDatesVIF(productid)
          // }

          // if(productid == '16' || productid == '17')
          // {
          //   getDatesSavings(productid)
          // }


      }else{
          console.log("Not logged in");
  
      }
    },[auth])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #3376b3',
      };

      const otpChange = (otp) => {
        console.log(otp);
        setOTP(otp)
        modalOTP['otp'] = otp;
      }

      function getDatesVault(productid)
      {
        console.log("get dates is now called")
        var invest_date = new Date().toISOString().split("T")[0];
        var current_dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      var current_date = new Date(current_dateNow);
      
        var dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      
        var date = new Date(dateNow);
          console.log(date);
        var y = date.getFullYear(),
          m = date.getMonth(),
          h = date.getHours();
            console.log(current_date.getDate());
            
        if(current_date.getDate() > 5 && current_date.getDate() != 5){
                    console.log("segment 2");
              var start_date = new Date(y, m+1, 2).toISOString().split("T")[0];
              var expire_date = new Date(y, m + 4, 6).toISOString().split("T")[0];
       }else {
            
            if(current_date.getDate() == 5 && h < 20)
            {
              console.log("segment 2");
              var start_date = new Date(y, m, 1).toISOString().split("T")[0];
              var expire_date = new Date(y, m + 3, 5).toISOString().split("T")[0];
            }else{
              console.log("segment 1");
              var start_date = new Date(y, m, 2).toISOString().split("T")[0];
              var expire_date = new Date(y, m + 3, 6).toISOString().split("T")[0];
            }
      
      
        }

        setVaultStartDate(start_date)
        setVaultEndDate(expire_date)
        return true;
      }

      function getDatesProduct(productid, duration)
      {
        console.log("get dates is now called")
        var invest_date = new Date().toISOString().split("T")[0];
        var current_dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      var current_date = new Date(current_dateNow);
      
        var dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      
        var date = new Date(dateNow);
          console.log(date);
        var y = date.getFullYear(),
          m = date.getMonth(),
          h = date.getHours();
            console.log(current_date.getDate());


          var newMonth = m + parseInt(duration);
          console.log(newMonth);
            
        if(current_date.getDate() > 5 && current_date.getDate() != 5){
                    console.log("segment 2");
              var start_date = new Date(y, m+1, 2).toISOString().split("T")[0];
              var expire_date = new Date(y, newMonth, 6).toISOString().split("T")[0];
       }else {
            
            if(current_date.getDate() == 5 && h < 20)
            {
              console.log("segment 2");
              var start_date = new Date(y, m, 1).toISOString().split("T")[0];
              var expire_date = new Date(y, newMonth, 5).toISOString().split("T")[0];
            }else{
              console.log("segment 1");
              var start_date = new Date(y, m, 2).toISOString().split("T")[0];
              var expire_date = new Date(y, newMonth, 6).toISOString().split("T")[0];
            }
      
      
        }

        setVaultStartDate(start_date)
        setVaultEndDate(expire_date)
        return true;
      }

      function getDatesVIF(productid)
      {

        var month = 4;
        if(productid == '18')
        {
          month = 4;
        }

        if(productid == '19')
        {
          month = 7;
        }
        
        if(productid == '20')
        {
          month = 13;
        }
        

        var invest_date = new Date().toISOString().split("T")[0];
        var current_dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      var current_date = new Date(current_dateNow);
      
        var dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      
        var date = new Date(dateNow);
          console.log(date);
        var y = date.getFullYear(),
          m = date.getMonth(),
          h = date.getHours();
            console.log(current_date.getDate());
            
            var start_date = new Date(current_date).toISOString().split("T")[0];

            // Payments should be 3 months if it is on the 5th
            // If it is before, it should expire also 3 months later but payments will be for 4 months as it should be for 3 FULL months
            // If it is after, it should expire 4 months later and payments will be for 4 months as it should be for 3 FULL months
                
            if (current_date.getDate() == 5 ) {

              if(h < 20)
              {
                console.log("on the 5th");
                var expire_date = new Date(y, m + (month - 1), 5).toISOString().split("T")[0];
              }else{
                console.log("after the 5th");
                var expire_date = new Date(y, m + month, 5).toISOString().split("T")[0];
              }
  
            } else if(current_date.getDate() < 5) {
              console.log("before the 5th");
              var expire_date = new Date(y, m + (month - 1), 5).toISOString().split("T")[0];
          
            }else{
              console.log("after the 5th");
              var expire_date = new Date(y, m + month, 5).toISOString().split("T")[0];
           }

        setVaultStartDate(start_date)
        setVaultEndDate(expire_date)
        return true;
      }

      function getDatesSavings(productid)
      {

        var month = 7;
        if(productid == '16')
        {
          month = 7;
        }

        if(productid == '17')
        {
          month = 13;
        }
        
        
        console.log("get dates is now called")
        var invest_date = new Date().toISOString().split("T")[0];
        var current_dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      var current_date = new Date(current_dateNow);
      
        var dateNow = new Date().toLocaleString('en-ZA', { timeZone: 'Africa/Johannesburg' });
      
        var date = new Date(dateNow);
          console.log(date);
        var y = date.getFullYear(),
          m = date.getMonth(),
          d = date.getDate(),
          h = date.getHours();

            console.log(current_date.getDate());
            var start_date = new Date(y, m, d+1).toISOString().split("T")[0];
            var expire_date = new Date(y, m + 6, d+1).toISOString().split("T")[0];
            

        setVaultStartDate(start_date)
        setVaultEndDate(expire_date)
        return true;
      }


      const acceptClicked = async() => {
        showSpinner = true;
        handleAccept()
      }

  return (
    <div>
      <Modal
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"

        className="form-no-background"
      >
        <Box sx={{
          ...style, width: 400,
          background:
            "	rgba(5,0,5,0.8)"
        }}>
          {!showSpinner ?
          <div>
          <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>Confirmation</h2>

          <p id="parent-modal-description" className=" mb-4">
            Product Plan Dates:
            <ul>
                <li className='text-base'>Start Date - {vaultStartDate}</li>
                <li >End Date - {vaultEndDate}</li>
            </ul>
          </p>

          <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                OTP
            </label>
            <input type="text" value={otp} onChange={(e) => otpChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
        </div>

          <p id="parent-modal-description" className=" mb-4 text-sm">
            <input type="checkbox" onChange={(e) => handleTC(e.target.checked)} checked={isAccept? 'checked' : ''} className='form-control text-white mr-2' />
            I hereby agree and accept the <span className="primary-color"><a href={Terms} target="BLANK"> terms and conditions </a></span> of this product.
          </p>

          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #3376b3",
            color: "#fff"}} className='c1-primary-btn' onClick={acceptClicked}>Agree</Button>

          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>
       
          </div>

          :

          <div role="status" className="m-auto">
            <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
          }
        </Box>
      </Modal>
    </div>
  )
}

ProductConfirmModal.propTypes = {
    closeModal: PropTypes.func,
    displayModal:PropTypes.any,
    modalOTP:PropTypes.any,
    handleAccept: PropTypes.func,
    showSpinner:PropTypes.any,
}

export default ProductConfirmModal