import React from 'react'

function DisplaySingleNotification({notification}) {
  return (
    <div className="container-md mx-auto px-4">
        {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            /> */}

        <div className="grid grid-cols-1 primary-border p-4">
            <div className="container-md px-4 primary-br-2">

                <div className="text-center mb-4">
                    <h1 className='underline text-white-800 text-lg'>{notification.title}</h1>
                </div>

                <div className="">
                    <div>
                        <p className='text-sm'>{notification.createdAt}</p>
                        <p>{notification.description}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default DisplaySingleNotification