import React, {useState, useEffect} from "react";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";



// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'


import axios from 'axios'

import { UserContext } from "../../../UserContext";

function RightMenu() {

    const{auth,user} = React.useContext(UserContext)
    const [notificationCounter, setNotificationCounter] = useState(0)

    React.useEffect(()=>{
  
        if(auth && user)
        {
            var userObj = {
                userid: user.user.userid
            }
        
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-notifications`,userObj).then((res)=>{
              if(res.data.status == 200)
              {
                console.log(res.data)
                setNotificationCounter(res.data.total)
              }else{
                console.log(res.data)
              }
            })
        }
      },[auth])

      const logout = async ()=>{

        var options =
        {
          isAdmin:true
        }
        axios.post(process.env.REACT_APP_BASE + "api/logout",options,{withCredentials:true}).then((res)=>{
          // window.location('/login-register');
         
          window.location.href="/login"
          
        })
      }


  return (
    <div className=" items-center justify-end md:flex md:flex-1 lg:flex lg:flex-1 lg:w-0">
            {/* <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a>
            <a
              href="#"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              Sign up
            </a> */}

<a href="/notifications" className="p-2 text-white-600 tooltip text-center border border-transparent rounded c1-primary-hover transition-colors duration-300">
            <div id='notification'>
            <FontAwesomeIcon id="notifications" icon={faBell} size="xl"  />
            <span className="badge">{notificationCounter}</span>
            </div>

            <ReactTooltip
                anchorId="notifications"
                place="bottom"
                variant="info"
                content="Notifications"
            />
         
        </a>

        <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">

        <a href="/settings" className="p-2 text-white-600 text-center border border-transparent rounded c1-primary-hover transition-colors duration-300">
         
            <FontAwesomeIcon id="settings" icon={faCog} size="xl"  />
            <ReactTooltip
                anchorId="settings"
                place="bottom"
                variant="info"
                content="Settings"
            />
        </a>

        {/* <a href="#" className="p-2 text-white-600 text-center border border-transparent rounded c1-primary-hover transition-colors duration-300">
            <FontAwesomeIcon id="notifications" icon={solid('user')} size="xl"  />
        </a> */}
        
        <button onClick={logout} className="p-2  text-white-600 text-center border border-transparent rounded c1-primary-hover transition-colors duration-300">
            <FontAwesomeIcon id="logout" icon={faSignOut} size="xl"  />
            <ReactTooltip
                anchorId="logout"
                place="bottom"
                variant="info"
                content="Log Out"
            />
        </button>
        </div>
    </div>
  )
}

export default RightMenu