// Table.js

import React from "react";
import { useTable } from "react-table";

export default function ProductTable({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table className="singleProductTable" {...getTableProps()} >
    <thead className="singleProductTable-head">
    {headerGroups.map(headerGroup => (
        <tr className="singleProductTable-tr" {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
            <th
            {...column.getHeaderProps()}
            style={{
                borderBottom: 'solid 3px #3376b3',
                // background: 'aliceblue',
                color: '#3376b3',
                fontWeight: 'bold',
                margin: '5px'
            }}
            >
            {column.render('Header')}
            </th>
        ))}
        </tr>
    ))}
    </thead>
    <tbody {...getTableBodyProps()}>
    {rows.map(row => {
        prepareRow(row)
        return (
        <tr className="singleProductTable-tr" {...row.getRowProps()}>
            {row.cells.map(cell => {
            return (
                <td
                {...cell.getCellProps()}
                style={{
                    padding: '10px',
                    border: 'solid 1px #3376b3',
                    // background: 'papayawhip',
                }}
                >
                {cell.render('Cell')}
                </td>
            )
            })}
        </tr>
        )
    })}
    </tbody>
    </table>
  );
}