import React from 'react'

import PropTypes from 'prop-types'

function TransactionHandleToModal({modalOTP}) {

    const [toValue, setToValue] = React.useState('');

    const currencyChange = (to) => {
        modalOTP["to"] = to;
        setToValue(to);
    };
  return (
    <div>
        <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                To Currency
            </label>
            <select value={toValue} onChange={(e) => currencyChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                <option value="">Select To Currency</option>
            {modalOTP['from'] !== 'usdt' ?
                <option value="usdt">USDT</option>
                :
                ''}
                 {modalOTP['from'] !== 'zar' ?
                <option value="zar">ZAR</option>
                :
                ''}
                {modalOTP['from'] !== 'btc' ?
                <option value="btc">BTC</option>
                :
                ''}
            </select>
        </div>

    </div>
  )
}

TransactionHandleToModal.propTypes = {
    modalOTP:PropTypes.any,
}

export default TransactionHandleToModal