import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoGif from "../../assets/uploads/logo_gif.png"
import errorimg from "../../assets/uploads/error404.png"

function Error404() {

    const[email, setEmail] =  React.useState('');
    const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)

    const submitButton = async() => {

        console.log("trying to send forgot email")
        await axios.post(process.env.REACT_APP_API_BASE_URL + `/forgot-password`,{email: email}).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
              // toastr.options.onHidden = function () {
              //   window.location.href = "/login"
              // }
    
              toast.success('Email has been successfully sent', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
              setAlreadySentFirstEmail(true)
            }else{
      
              toast.error('Failed to send email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
          })
    }

    const resendEmail = async () => {
        await axios.post(process.env.REACT_APP_BASE + "api/forgot-password-alternative",{email: email}).then(async res =>{

            if(res.data.status == 200)
            {
              // toastr.options.onHidden = function () {
              //   window.location.href = "/login"
              // }
    
              toast.success('Email has been successfully sent', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
              setAlreadySentFirstEmail(true)
            }else{
      
              toast.error('Failed to send email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
         
          });
    }

    const updateEmail = async (email) => {
        setEmail(email);
    }
  return (
    <React.Fragment>
    <div className="website-main-bg rounded-xl shadow border h-screen">
    <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div className="px-6 h-full text-white-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >

            <img
              src={logoGif}
              className="w-full"
              alt="Sample image"
            />
          </div>

          <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form>

            <div className="mb-4">
                <img src={errorimg} className="text-center m-auto" />
              <h1 className="text-white text-center text-4xl">Oops!</h1>

              <h1 className="text-white text-center text-md">The page you were loking for does not exist.</h1>
            </div>

            <div>
            <p className="text-xs text-center font-semibold mt-2 pt-1 mb-0 text-white">Please contact info@nessfinassets.io if you have any qustions.</p>
            </div>
 

            </form>
          </div>
          
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

export default Error404