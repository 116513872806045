import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'

function Withdraw() {

    const [modalOTP, setModalOTP] = React.useState({otp: '', from: '', amount: '', type: 'withdraw', reference: ''});

    const[modalDisplay, setModalDisplay] =  React.useState(false);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    const[canContinue, setCanContinue] =  React.useState(true);

    React.useEffect(()=>{
    
      if(auth && user)
      {
          console.log("Logged in ", auth, user);
          setUserid(user.user.userid)
      }else{
          console.log("Not logged in");
  
      }
    },[auth])

    const openTransactionModal = () => {

        setModalDisplay(true)
        console.log("Open the modal")
    }

    const closeModal = () => {
        setModalDisplay(false)
    }

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
    }

    const handleAccept = async () => {
        console.log("submitted the following ", modalOTP)

        if(modalOTP['amount'] <= 0)
        {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(modalOTP['reference'] === '')
        {
            toast.error('Please Enter Valid Reference or TXID', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(canContinue)
        {
          setCanContinue(false)

        var otpObj = {
            userid: userid,
            otp: modalOTP['otp']
          }
          
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                  
            if(res.data.status !== 200)
            {

                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    await sendOTP();
                    setCanContinue(true)

                return false;
            }else{
          
                return true;
            }
        });

        if(!checkOtp)
            return false;

        
            var balanceObject = {
                userid: userid,
                currency: currencyConvert[modalOTP['from']]
              }
    
         
    
          
            var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{
          
              console.log('set the balance to ', res.data)
              if(parseFloat(res.data.total) < modalOTP['amount'])
              {
                toast.error('Insufficient Balance!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                    setCanContinue(true)
                    return false;
              }else{
                return true
              }
           
            })

            if(!checkBalance)
            {
              setCanContinue(true)
              return false;
            }
             

            var finalAmount = modalOTP['amount'] * -1;
        const withdrawObject = {
            userid: userid,
            withdrawal_address: modalOTP['reference'],
            amount: finalAmount,
            tran_cat: '2',
            currency: currencyConvert[modalOTP['from']],
            type: "Adding Withdrawal"
          }
        
          
          var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction",withdrawObject).then(async res =>{

            console.log("got the deposit response ", res.data)

            if(res.data.status == 200)
            {
                toast.success('Withdraw Created Successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    setTimeout(() => {window.location.reload(); }, 2000);
            }else{
                toast.error('Unable To Create Withdraw', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                    setCanContinue(true)
            }

          });

        }

        // setModalDisplay(false)
      }

      const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
            console.log(res.data)
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

        

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

        {modalDisplay ? 
            <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} title="Withdraw" />
            : ''
        }
        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Withdraw</h1>

        <div className="container-md mx-auto px-4">

       
            <div className="container-md px-4 primary-br-2">

                <div className="">
                <ol type="1">
                        <li>Withdrawals can be made in the following currencies:
                            <ul className='ml-3' style={{listStyleType:"disc"}}>
                                <li style={{listStyleType:"disc"}}>ZAR</li>
                                <li style={{listStyleType:"disc"}}>USDT</li>
                                <li style={{listStyleType:"disc"}}>Bitcoin</li>
                            </ul>
                        </li>
          
                        <li>All proceeds are paid into your USDT wallet and withdrawals made in other currencies would first need to be exchanged to the specific currency (exchange fee is applicable) before a withdrawal can be processed.</li>
                        <li>Withdrawals can be made between the 2nd and the 5th day of each month and may take up to 24 hours for approval, public holidays and weekends are excluded.</li>
                        <li>USDT payments will be made to a USDT TRON Wallet Address</li>
                    </ol>  

                </div>

                <div className='m-auto text-center'><button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center'>Withdraw Now</button></div>
                
            </div>

  

        </div>
       

    
    </div>
  )
}

export default Withdraw