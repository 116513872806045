import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoGif from "../../assets/uploads/logo_gif.png"

function ForgotPassword() {

    const[email, setEmail] =  React.useState('');
    const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)

    const submitButton = async() => {

        console.log("trying to send forgot email")
        await axios.post(process.env.REACT_APP_API_BASE_URL + `/forgot-password`,{email: email}).then((res)=>{
            console.log(res.data)
            if(res.data.status == 200)
            {
              // toastr.options.onHidden = function () {
              //   window.location.href = "/login"
              // }
    
              toast.success('Email has been successfully sent', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
              setAlreadySentFirstEmail(true)
            }else{
      
              toast.error('Failed to send email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
          })
    }

    const resendEmail = async () => {
        await axios.post(process.env.REACT_APP_BASE + "api/forgot-password-alternative",{email: email}).then(async res =>{

            if(res.data.status == 200)
            {
              // toastr.options.onHidden = function () {
              //   window.location.href = "/login"
              // }
    
              toast.success('Email has been successfully sent', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
              setAlreadySentFirstEmail(true)
            }else{
      
              toast.error('Failed to send email', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
         
          });
    }

    const updateEmail = async (email) => {
        setEmail(email);
    }
  return (
    <React.Fragment>
    <div className="website-main-bg rounded-xl shadow border h-screen">
    <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div className="px-6 h-full text-white-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >

            <img
              src={logoGif}
              className="w-full"
              alt="Sample image"
            />
          </div>

          <div style={{padding: "20px", borderRadius: "15px"}} className="website-light-dark-bg xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form>

            <div className="mb-4">
              <h1 className="text-white text-center text-2xl">Forgot Your Password</h1>

            
            </div>
 
              <div className="mb-6">
                <input
                  type="email"
                  className="form-control block w-full px-4 py-2 text-xl font-normal website-text-light-bg website-text-color bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Email"
                  onChange={(e) => {updateEmail(e.target.value)}}
                 
                  value={email}
                />
              </div>


              <div className="text-center lg:text-left">
                <button type="button" className="website-primary-btn " onClick={(e) => {submitButton(e)}}>
                  Submit
                </button>

                {alreadySentFirstEmail ?
                <p className="text-sm font-semibold mt-2 pt-1 mb-0 text-white">Have not yet received your emai?<button onClick={(e) => {resendEmail(e)}} className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out"> Resend Email</button></p>
                    : ''}
                </div>

            </form>
          </div>
          
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

export default ForgotPassword