import React from 'react'

import FundForm from '../../components/Products/Forms/FundForm'

function InflationCountIndex() {
    const products = [
        {
            mainTitle: 'Inflation Counter Index',
            title: "Inflation Counter Index (6 Months)",
            duration: "6",
            fixedStart: false,
            startDate: '',
            min: 500,
            endDate: '05',
            toWallet: true,
            productid: '19',
            description: `<p>Product Objective:</p>
            <p>To give Blockchain returns on contributions.</p><br />
            
            <p>Contribution Period:</p>
            <p>A Six-month locked capital contribution.</p><br />
            
            <p>After Six Months:</p>
            <p>The initial Contribution will automatically be re-contributed to the vault Fund for another 6 months if not otherwise requested to be withdraw.</p><br />
            
            <p>Proceeds:</p>
            <p>Monthly or quarterly.</p><br />

            <p>Minimum Contribution: </p>
            <p>Minimum Contribution:  $10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Inflation Counter Index',
            title: "Inflation Counter Index (12 Months)",
            duration: "12",
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: true,
            productid: '20',
            description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br />
            
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br />
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>`
        }
    ]
    return (
        <div className="container mx-auto px-2 pt-8 pb-8">
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Inflation Counter Index</h1>

          <FundForm products={products} min={products[0].min} />

          
        </div>
      )
}

export default InflationCountIndex