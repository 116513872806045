import React from 'react'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'

function SingleTransaction(props) {
    const renderSwitch = async(param) => {
        switch(param) {
            case '1':
                console.log("is plus")
                return 'plus';
            case '2':
                return 'minus';
            case '3':
                return 'minus';
            case '4':
                return 'plus';
            case '5':
                return 'minus';
            case '6':
                return 'plus';
            case '7':
                return 'plus';
            case '8':
                return 'plus';
            default:
                return 'plus';
        }
      }

      var productIcons = {
        "1": faPlus,
        "2": faMinus,
        "3": faMinus,
        "4": faPlus,
        "5": faMinus,
        "6": faPlus,
        "7": faPlus,
        "8": faPlus,
        "9": faMinus,
        "10": faMinus,
        "11": faMinus,
    }

    var productIcons2 = {
        "1": 'plus',
        "2": 'minus',
        "3": 'minus',
        "4": 'plus',
        "5": 'minus',
        "6": 'plus',
        "7": 'plus',
        "8": 'plus',
        "9": '-',
        "10": 'minus',
        "11": 'minus',
    }

    var currency = {
        '1' : 'USDT',
        '2' : 'ZAR',
        '3' : 'BTC'
    }

    var status = {
        '0' : 'Pending',
        '1' : 'Approved',
        '2' : 'Declined'
    }

    var color = {
        '0' : 'orrange',
        '1' : 'green',
        '2' : 'red'
    }

    function numberWithSpaces(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    }

  return (
    <div>
        <div className="grid grid-cols-2 mt-2 border-t border-white p-2">
            <div className="grid grid-cols-3">
                <div className='m-auto'><FontAwesomeIcon icon={(props.transaction.tran_type === 'Created Exchange' ? faMinus : productIcons[props.transaction.tran_cat])} size="xl" style={{color: productIcons2[props.transaction.tran_cat] === "plus" && props.transaction.tran_type !== 'Created Exchange'  ? 'green': 'red'}} className="inline-block text-center" /></div>
                <div className="col-span-2 ...">
                    <p className="text-sm">{props.transaction.createdAt}</p>
                    <h2 >{props.transaction.tran_type} <span className='text-sm' style={{ color: color[props.transaction.status]}}>({status[props.transaction.status]})</span></h2>
                </div>
            </div>

            <div className="m-auto text-right">
                <h1 >{currency[props.transaction.currency]} {(props.transaction.amount > 0 ? numberWithSpaces(parseFloat(props.transaction.amount)) : numberWithSpaces(parseFloat(props.transaction.amount) * -1))}</h1>
            </div>
        </div>
    </div>
  )
}

export default SingleTransaction