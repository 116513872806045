import React from 'react'

import PropTypes from 'prop-types'

function TransactionHandleModal({modalOTP}) {

    const [fromValue, setFromValue] = React.useState('');

    const currencyChange = (from) => {
        modalOTP["from"] = from;
        setFromValue(from)
    };
  return (
    <div>
        <div className="mb-4">
            <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                Currency {modalOTP["type"] === "exchange" ? "From" : ''}
            </label>
            <select value={fromValue} onChange={(e) => currencyChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                <option value="">Select Currency</option>
                <option value="usdt">USDT</option>
                <option value="zar">ZAR</option>
                <option value="btc">BTC</option>
            </select>
        </div>

    </div>
  )
}

TransactionHandleModal.propTypes = {
    modalOTP:PropTypes.any,
}

export default TransactionHandleModal