import React from 'react'

import FundForm from '../../components/Products/Forms/FundForm'

function AccumulationFund() {

    const products = [
        {
            mainTitle: 'Transaction Accumulation Fund',
            title: "Accumulation Fund (3 Months)",
            duration: {3: 15},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
            productid: '15',
            description: `<p>Product Objective:</p>
            <p>To give Blockchain returns on contributions.</p><br />
            
            <p>Contribution Period:</p>
            <p>A Six-month locked capital contribution.</p><br />
            
            <p>After Six Months:</p>
            <p>The initial Contribution will automatically be re-contributed to the vault Fund for another 6 months if not otherwise requested to be withdraw.</p><br />
            
            <p>Proceeds:</p>
            <p>Monthly or quarterly.</p><br />

            <p>Minimum Contribution: </p>
            <p>Minimum Contribution:  $10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        }
    ]
    return (
        <div className="container mx-auto px-2 pt-8 pb-8">
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Accumulation Fund</h1>

          <FundForm products={products[0]} min={products[0].min} />

          
        </div>
      )
}

export default AccumulationFund