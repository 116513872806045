import React, {useState, useEffect} from "react";

import SingleNotification from './SingleNotification'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import { withRouter, Link, useLocation } from "react-router-dom"

import {decode as base64_decode, encode as base64_encode} from 'base-64';

import DisplaySingleNotification from "./DisplaySingleNotification";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function NotificationsContainer({filter, showAll}) {

    const[displayNotifications, setDisplayNotifications] =  React.useState(true);

    const{auth,user} = React.useContext(UserContext)
    const [notifications, setNotifications] = useState([])

    const [showSingleView, setShowSingleView] = useState(false)
    const [singleNotificationInfo, setSingleNotificationInfo] = useState([])

    const [userid, setUser] = useState('')

    let query = useQuery()
    let userToken = query.get("n_id");

    const getSingleNotifications = async (userid) => {
      if(userToken)
      {
        let decoded = base64_decode(userToken);
  
        var notificationObj = {
          n_id: decoded,
          userid: userid
        }

        console.log("notification obj ", notificationObj);
  
        await axios.post(process.env.REACT_APP_API_BASE_URL + `/get-singal-notification`,notificationObj).then(async (res)=>{
          console.log(res.data)
          if(res.data.status === 200)
          {
            setShowSingleView(true)
            setSingleNotificationInfo(res.data.notification)

            if(res.data.notification.status === 'new')
            {

              console.log("this is a new notification")

              var notificationObj = {
                n_id: decoded,
                userid: userid
              }
              await axios.post(process.env.REACT_APP_API_BASE_URL + `/update-notification`,notificationObj).then((resUpdate)=>{
                if(resUpdate.status === 200)
                {
                  return true;
                }
              })
            }
          }else{
  
          }
  
        })
        
      }

      return false;
    }


    React.useEffect(()=>{
    
      if(auth && user)
      {

        setUser(user.user.userid)

        var userObj = {
            userid: user.user.userid
        }
    
        if(displayNotifications && filter)
        {
          getAllUserNotifications(user.user.userid)
        }else{
          axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-notifications`,userObj).then((res)=>{
            if(res.data.status == 200)
            {
              console.log(res.data.notifications)
              setNotifications(res.data.notifications)
            }else{
              console.log(res.data)
     
            }
          })
        }

        var singleNotifications = getSingleNotifications(user.user.userid)
      }else{
          console.log("Not logged in");
  
      }

    },[auth])

    const getAllUserNotifications = async(userid) => {

      var userObj = {
        userid: userid
      }
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-all-notifications`,userObj).then((res)=>{
        if(res.data.status == 200)
        {
          setNotifications(res.data.notifications)
        }else{
          toast.error('Unable to get notifications', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
 
        }
      })
    }


    // const notifications = [
    //     {
    //         date: '2022-04-17',
    //         title: "Deposit Received",
    //         description: "Deposit is received and will be displayed in your wallet"
    //     },
    //     {
    //         date: '2022-03-02',
    //         title: "KYC Completed",
    //         description: "KYC is approved and you will now be able to deposit"
    //     },
    //     {
    //         date: '2022-02-16',
    //         title: "KYC Required",
    //         description: "KYC is Reuired by navigating to settings and click on the KYC button"
    //     },
    
    // ]

    const toggleNotification = (toggle) => {

        setDisplayNotifications(!displayNotifications);
        console.log(displayNotifications)
    }

    const clearAllNotifications = async () => {

      var userObj = {
        userid: userid
      }

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/clear-all-user-notifications`,userObj).then((res)=>{
        console.log("clear notifications response received ", res.data)
        if(res.data.status == 200)
        {
          toast.success('Notifications Cleared', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
            setTimeout(() => {window.location.reload(); }, 2000);
        }else{
          toast.error('Unable to clear new notifications', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
 
        }
      })
    }
  return (
    <div>

<ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
          
    <div>{showAll ? '' : "Recent"} <span className="primary-color">Notifications <FontAwesomeIcon onClick={(e)=> {toggleNotification(e.target.value)}} className="pl-2" icon={faCaretDown} size="xl"  /></span></div>

    {displayNotifications && filter ? 
            <div className='mt-4 mb-4'>
              <button onClick={clearAllNotifications} className="c1-primary-btn-sm ">Mark All As Read</button>
                {/* <div className="mb-4">
             
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">
                        Fileter By Date:
                    </label>

                    <select  className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline">
                        <option value="">Show All</option>
                        <option value="">Jan 2022</option>
                        <option value="">Feb 2022</option>
                        <option value="">March 2022</option>
                        <option value="">Apr 2022</option>
                        <option value="">May 2022</option>
                    </select>
                </div> */}
            </div> 
            : ''}
    
    
    
    {displayNotifications ?
    <div className={showAll ? '' : ""}>

    <div  className={displayNotifications && filter ? 'allNotificationsContainer' : ''}>
        {(typeof notifications !== 'undefined' && notifications.length > 0) ?
        notifications.map((notification,key) => (
            <SingleNotification key={key} notification={notification} />
        ))
        : <p className="pl-2">No Notifications</p>
        }
 
    </div>

    {showSingleView ? 
        <div className="mt-6 notification-container">
          
          <DisplaySingleNotification notification={singleNotificationInfo} />
     
        </div>
    : ''}
    </div>
    : ''}
</div>
  )
}

export default NotificationsContainer