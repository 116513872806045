import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { display } from '@mui/system';

import { UserContext } from "../../../UserContext";
import axios from 'axios'

import PDFTransactions from "../../PDF/PDFTransactions";
import { PDFDownloadLink } from '@react-pdf/renderer'

function TransactionsReport() {

  const[displayNotifications, setDisplayNotifications] =  React.useState(true);
  const{auth,user} = React.useContext(UserContext)

  const [transactions, setTransactions] = useState([])
  const[name, setName] =  React.useState('');
  const[userid, setUserid] =  React.useState('');
  const[useDate, setUseDate] =  React.useState('');
  const[totalZAR, setTotalZAR] =  React.useState(0);
  const[totalUSDT, setTotalUSDT] =  React.useState(0);
  const[totalBTC, setTotalBTC] =  React.useState(0);
  
  
  React.useEffect(()=>{
  
      if(auth && user)
      {
          setName(user.user.name + ' ' + user.user.lastname)
          setUserid(user.user.userid)
          let newDate = new Date()
          let date = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          let newstring = ''
          if(month<10)
              newstring = '0' + month
          else
              newstring = month

          setUseDate(year + '-' + newstring + '-' + date)
        var userObj = {
            userid: user.user.userid
        }

        getUserBalances(user.user.userid)
    
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-transactions`,userObj).then((res)=>{
          if(res.data.status == 200)
          {
            console.log(res.data.transactions)
            setTransactions(res.data.transactions)
          }else{
            console.log(res.data)
   
          }
        })
      }else{
          console.log("Not logged in");
  
      }

    },[auth])

    const getUserBalances = async(userid) => {
      var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            console.log(res.data)
            setTotalZAR(res.data.currencyInfo[1].balance)
            setTotalUSDT(res.data.currencyInfo[0].balance)
            setTotalBTC(res.data.currencyInfo[2].balance)
          }else{
            console.log(res.data)
          }
        })

        return true;
    }
  return (
    <div className='mt-8'>
          
    <div>My <span className="primary-color">Transactions <FontAwesomeIcon className="pl-2" icon={faCaretDown} size="xl"  /></span></div>

    <PDFDownloadLink document={<PDFTransactions title="Transaction Report" name={name}  info={transactions} userid={userid} date={useDate} totalUSDT={totalUSDT} totalBTC={totalBTC} totalZAR={totalZAR} />} fileName={"Transactions-" + useDate} >
                    {({loading }) => (loading ? "Loading..." : <button  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Download Report
                </button>)}
                </PDFDownloadLink>

</div>
  )
}

export default TransactionsReport