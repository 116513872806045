import { Fragment } from 'react'
import { createPopper } from "@popperjs/core";

import { Link } from "react-router-dom";

import { Popover, Transition } from '@headlessui/react'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


  const Menu = ({products, transactions, userInfo }) => {

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }



  return (
    <div className='c1-navbar'>
      <Popover.Group as="nav" className="hidden space-x-10 md:flex">

        <a href="/dashboard" className="p-2 lg:px-4 md:mx-2 navbar-menu-c1 text-white rounded primary-border">
          Dashboard
        </a>

        {userInfo.fica_status === 'A' && userInfo.u_status === 'A'?
   
        <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames('p-2 lg:px-4 md:mx-2 navbar-menu-c1 text-white rounded '
                    )}
                  >
                    <span>Products <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:primary-border inline'
                      )}
                      aria-hidden="true"
                    /></span>
                    
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-black primary-border px-5 py-6 sm:gap-8 sm:p-8">
                          {products.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-3 c1-primary-hover hover:primary-border"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-white">{item.name}</p>
                        
                              </div>
                            </a>
                          ))}
                        </div>
                        
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
        </Popover>
        : ''}

        {/* Transactions */}

        {userInfo.fica_status === 'A' && userInfo.u_status === 'A'?
        <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames('p-2 lg:px-4 md:mx-2 navbar-menu-c1 text-white rounded '
                    )}
                  >
                    <span>Transactions <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:primary-border inline'
                      )}
                      aria-hidden="true"
                    /></span>
                    
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-black primary-border px-5 py-6 sm:gap-8 sm:p-8">
                          {transactions.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start c1-primary-hover rounded-lg p-3 hover:primary-border"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 primary-color" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-white">{item.name}</p>
                        
                              </div>
                            </a>
                          ))}
                        </div>
                        
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
        </Popover>
        : ''}

        <a href="/reports" className="p-2 lg:px-4 md:mx-2 navbar-menu-c1 text-white rounded c1-primary-hover ml-0 transition-colors duration-300">
          Reports
        </a>


          </Popover.Group>
    </div>
  )
}

export default Menu