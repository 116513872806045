import React, {useState, useEffect} from "react";
import ProductsContainer from '../../components/Containers/Products/ProductsContainer'
import TransactionContainer from '../../components/Containers/Transactions/TransactionContainer'
import TransactionGraph from '../../components/Containers/Transactions/TransactionGraph'
import NotificationsContainer from '../../components/Containers/Notifications/NotificationsContainer'
import AccountCompletion from '../../components/User/AccountCompletion/AccountCompletion'

import TransactionModal from '../../components/Modals/TransactionModal'

// import Modal from 'react-modal';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { UserContext } from "../../UserContext";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Dashboard() {

  const{auth,user} = React.useContext(UserContext)
  const[userid, setUserid] =  React.useState('');
  const[productData, setProductData] =  React.useState([]);
  const[productInterest, setProductInterest] =  React.useState([]);
  const[userKYC, setUserKYC] =  React.useState([]);
  const[userActive, setuserActive] =  React.useState('');

  const [modalOTP, setModalOTP] = React.useState({otp: '', from: '', amount: '', type: 'deposit', reference: ''});
  const[depositModalDisplay, setDepositModalDisplay] =  React.useState(false);
  const[canContinue, setCanContinue] =  React.useState(true);
  
  const[displayModal, setDisplayModal] =  React.useState(false);
  
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    border: '2px solid #3376b3',
  };


  const getUserProductData = async (userid) => {
        
    var userObj = {
        userid: userid
      }
  
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/all-user-open-products`,userObj).then(async (res)=>{
        if(res.data.status == 200)
        {
          console.log('product data is ', res.data)
          setProductData(res.data.total)
        }else{
          console.log('product data error is ', res.data)
        }
      })

      return true;
  }

  const ficaStatus = {
    'N': 0,
    'P': 1,
    'A': 2,
  }

  const activeStatus = {
    'N': 0,
    'P': 1,
    'A': 2,
    'L': 3,
  }

  const currencyConvert = {
    'usdt' : '1',
    'zar' : '2',
    'btc' : '3',
}

  React.useEffect( () => {
  
    if (auth != null) {
      if (!auth) {
        window.location.href="/login"
      }else{
        setUserid(user.user.userid)
        getUserProductData(user.user.userid)
        setUserKYC(user.user.fica_status)
        setuserActive(user.user.u_status)
        if(user.user.fica_status === 'N')
        {
          setDisplayModal(true)
        }
        if(user.user.fica_status === 'P')
        {

          getDepositFunc(user.user.userid)
        
        }
      }
    }
  }, [auth])

  const closeModal = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setDisplayModal(false)
  }

  const navifateToKYC = (event, reason) => {
    window.location.href="/settings"
  }

  const closeDepositModal = () => {
    setDepositModalDisplay(false)
}

const openTransactionModal = () => {

  setDepositModalDisplay(true)
  console.log("Open the modal")
}

const handleAccept = async () => {


  console.log("submitted the following ", modalOTP)

  if(modalOTP['amount'] <= 0)
  {
      toast.error('Please Enter Valid Amount', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

          return false;
  }

  if(modalOTP['reference'] === '')
  {
      toast.error('Please Enter Valid Reference or TXID', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

          return false;
  }

  if(canContinue)
  {
      setCanContinue(false)
      var otpObj = {
          userid: userid,
          otp: modalOTP['otp']
      }
      
      const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
              
          if(res.data.status !== 200)
          {
              
              toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });

                  await sendOTP();

                  setCanContinue(true)

              return false;
          }else{
              return true;
          }
      });

      if(!checkOtp)
          return false;

      const depositObject = {
          userid: userid,
          txid: modalOTP['reference'],
          amount: modalOTP['amount'],
          tran_cat: '1',
          currency: currencyConvert[modalOTP['from']],
          type: "Adding Deposit"
      }
      
      
      var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction",depositObject).then(async res =>{

          console.log("got the deposit response ", res.data)

          if(res.data.status == 200)
          {
              toast.success('Deposit Created Successfully!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });

                  setTimeout(() => {window.location.reload(); }, 2000);

                 
          }else{
              toast.error('Unable To Create Deposit', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });

                  setCanContinue(true)
          }

      });
  }

  // setModalDisplay(false)
}

const sendOTP = async () => {

  var otpObj = {
      userid: userid
    }

  await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
      console.log(res.data)
if(res.data.status == 200)
{

  toast.success('OTP Pin Successfully Sent!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });

}else{

  toast.error('Unable To send OTP!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
}

})

}

const getDepositFunc = async (userid) => {
  var userObjDeposit = {
    userid: userid,
  }
  const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-any-deposits",userObjDeposit).then( async(res)=>{

    console.log("the response we received from deposit is ", res.data)
            
    if(res.data.status !== 200)
    {
      setDepositModalDisplay(true)
    }
});
}

  return (
    <div className="container mx-auto px-2 mb-8">

    <Modal
        open={displayModal}
        onClose={closeModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"

        className="form-no-background"
      >
        <Box sx={{
          ...style, width: 400,
          background:
            "	rgba(5,0,5,0.8)"
        }}>
          <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>Notice</h2>

          <p id="parent-modal-description" className=" mb-4">
            {userKYC === 'N' ? 
              'Please complete the KYC process in order to make transactions and purchase a product.'
              : ''}
              
          </p>

          {userKYC === 'N' ?
          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #3376b3",
            color: "#fff"}} className='c1-primary-btn' onClick={navifateToKYC}>Continue To KYC</Button> 
            : ''}   

          <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>      
        </Box>
      </Modal>

      {/* <TransactionModal modalDisplay={depositModalDisplay} modalOTP={modalOTP} closeModal={closeDepositModal} handleAccept={handleAccept} title="Deposit" /> */}
      <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Dashboard</h1>

      <div className='spacercontainer' />

      {/* <hr style={{color: "#12d0ff"}} className="mb-6" /> */}

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2">

      <AccountCompletion userid={userid} kyc={ficaStatus[userKYC]} account={activeStatus[userActive]} />

      <NotificationsContainer />

      </div>

      <div className='spacercontainer' />

      <ProductsContainer />

      <div className='spacercontainer' />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-6">
    
  
        <TransactionContainer  filter={false} showAll={false} />
        {typeof productData == 'undefined' && productData.length > 0 ?
          <TransactionGraph productData={productData} />
          : ''}
      </div>

    </div>
  )
}

export default Dashboard