import React from 'react'

import FundForm from '../../components/Products/Forms/FundForm'

function SavingsVault() {
    const products = [
        {
            mainTitle: 'Savings Vault',
            title: "Savings Vault (6 Months)",
            duration: "6",
            fixedStart: false,
            startDate: '',
            min: 100,
            endDate: '05',
            toWallet: false,
            productid: '16',
            description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br />
            
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br />
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>`
        },
        {
            mainTitle: 'Savings Vault',
            title: "Savings Vault (12 Months)",
            duration: "12",
            fixedStart: false,
            startDate: '',
            endDate: '05',
            toWallet: false,
            productid: '17',
            description: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br />
            
            <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
            dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br />
            
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>`
        }
    ]
    return (
        <div className="container mx-auto px-2 pt-8 pb-8">
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Savings Vault</h1>

          <FundForm products={products} min={products[0].min} />

          
        </div>
      )
}

export default SavingsVault