import React from 'react'
import { useParams } from 'react-router';

import FundForm from '../../components/Products/Forms/FundForm'
import { UserContext } from '../../UserContext'

function ProductsPage() {

    const{auth,user} = React.useContext(UserContext)
    const [productData, setProductData] = React.useState([]);
    const [productDataTitle, setProductDataTitle] = React.useState('');
    const [redirectToDash, setRedirectToDash] = React.useState(true);

    let { product } = useParams()

    const products = [
        {
            mainTitle: 'Capital Interest Vault',
            slug: "capital-interest-vault",
            title: "Capital Interest Vault",
         
            duration: {6: 22, 12 : 23, 36: 24, 60: 25},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
             productid: '',
            description: `<p>Product Objective:</p>
            <p>Cryptocurrency deposits continue to generate high returns due to the demand for borrowing assets that are not easily accessible through traditional means. However, as the crypto market evolves and capital becomes more readily available, the yields will gradually decrease. 
            Despite this, we believe that there will be opportunities to generate yield to offset the increasing accessibility of capital in the crypto space.</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months, but 12 months, 3- and 5-year options also available. Funds are locked for the 6-month period (or alternative period chosen) and profits are paid back into the fund. 
            At the end of the 6-month (or alternative period chosen) the funds and profits can be withdrawn or re-invested.</p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out monthly on the 5th day of each month. 
            At the end of the 6-month period (or alternative period chosen) the funds and profits can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Savings Bundle',
            slug: 'savings-bundle',
            title: "Savings Bundle",
            duration: {12 : 26, 36: 27, 60: 28},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
             productid: '',
            description: `<p>Product Objective:</p>
            <p>Our investment strategy is typically focused on smart capital growth and may involve a diversified portfolio of blockchain investments. 
            Overall, our funds investment strategy is designed to help investors build wealth over the long term by investing in a diversified portfolio of assets and reinvesting profits back into the fund. 
            This can help to maximize returns while managing risk and volatility over time.</p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3 and 5-year options also available. Funds are locked for the 12-month period and profits are paid back into the fund. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out annually. At the end of the 12-month period the funds and profits can be withdrawn or re-invested.</p><br />

            <p>Minimum Contribution: </p>
            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Tokenized Index',
            slug: 'tokenized-index',
            title: "Tokenized Index",
            duration: {6: 29, 12 : 30, 36: 31, 60: 32},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The Tokenized Index Fund is a proposed investment fund that aims to provide stability in the face of inflation using cryptocurrencies as its underlying asset. 
            The fund will use a basket of the top 10 cryptocurrencies, carefully selected for their potential to provide a hedge against inflation and maintain value over time.
            Overall, the Tokenized Index Fund aims to provide investors with a stable and reliable investment option that can help them preserve the value of their wealth and hedge against inflation. By investing in a carefully curated basket of cryptocurrencies, the fund offers an innovative solution that can help investors achieve their financial goals while minimizing risk.
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 6 Months, but 12 months, 3- and 5-year options also available. Funds are locked for the 6-month period (or alternative period chosen) and profits are paid back into the fund. 
            At the end of the 6-month (or alternative period chosen) the funds and profits can be withdrawn or re-invested.</p><br />
        
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out Quarterly. At the end of the 6-month period (or alternative period chosen) the funds and profits can be withdrawn or re-invested. </p><br />

            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Monetary Stability Fund',
            slug: 'monetary-stability-fund',
            title: "Monetary Stability Fund",
            duration: {12 : 33, 36: 34, 60: 35},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The fund's strategy is to invest in cryptocurrencies that are expected to appreciate in value at a rate that exceeds the rate of inflation. 
            By doing so, the fund seeks to outperform traditional investment options and provide a viable alternative for investors seeking protection against inflation.

            The Monetary Stability Fund aims to provide investors with a stable and reliable investment option that can help them preserve the value of their wealth and hedge against inflation. By investing in a carefully curated basket of cryptocurrencies, the fund offers an innovative solution that can help investors achieve their financial goals while minimizing risk. 
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3- and 5-year options also available. Funds are locked for the 12-month period and profits are paid back into the fund. </p><br />
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out Quarterly. At the end of the 12-month period the funds and profits can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        },
        {
            mainTitle: 'Synthetic Assets',
            slug: 'synthetic-assets',
            title: "Synthetic Assets",
            duration: {12 : 36, 36: 37, 60: 38},
            fixedStart: true,
            startDate: '01',
            endDate: '05',
            toWallet: true,
            min: 1000,
             productid: '',
            description: `<p>Product Objective:</p>
            <p>The Synthetic Assets is a proposed investment fund that aims to provide stability in the face of inflation using cryptocurrencies as its underlying asset. 
            The fund will use a basket of the top 10 cryptocurrencies, carefully selected for their potential to provide a hedge against inflation and maintain value over time.

            Overall, the Synthetic Assets aims to provide investors with a stable and reliable investment option that can help them preserve the value of their wealth and hedge against inflation. By investing in a carefully curated basket of cryptocurrencies, the fund offers an innovative solution that can help investors achieve their financial goals while minimizing risk.
            </p><br />
            
            <p>Contribution Period:</p>
            <p>Minimum 12 Months, but 3- and 5-year options also available. Funds are locked for the 12-month period and profits are paid back into the fund. 
            At the end of the 12-month period the funds and profits can be withdrawn or re-invested.</p><br />
        
            
            <p>Proceeds:</p>
            <p>Proceeds are paid out Quarterly. At the end of the 12-month period the funds and profits can be withdrawn or re-invested. </p><br />

            <p>Minimum Contribution: </p>
            <p>$10,000</p><br />

            <p>There are no guarantees on any proceeds. Contributions are safeguarded with an approved risk management process.</p><br />
            `
        }
    ]

    React.useEffect(res =>{
        // var redirectToDash = true;
        for(var i in products)
        {
            console.log(products[i])
            if(products[i].slug == product)
            {
                console.log("stop", products[i].mainTitle)
              
                setRedirectToDash(false)
                setProductData(products[i])
                setProductDataTitle(products[i].mainTitle)
                return;
            }
        }

        // if(redirectToDash)
        //     window.location.href="/dashboard"

    },[auth])

    return (
        <div className="container mx-auto px-2 pt-8 pb-8">
          <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">{productDataTitle}</h1>
            {!redirectToDash ?
            <FundForm products={productData} min={productData.min} />
            : ''}

          
        </div>
      )
}

export default ProductsPage