import PropTypes from "prop-types"
import React from "react"
import { useEffect } from "react"


import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// Layouts
import NonAuthLayout from "./components/NonAuthLayout"
import HorizontalLayout from "./components/HorizontalLayout/"

import { UserContext } from "./UserContext"
import axiosApp from "./axiosConfig"


const App = props => {

  const [user, setUser] = React.useState(null)
  var [auth, setAuth] = React.useState(null)

  const isAuthenticated = () => {

    var isAdmin = true

    axiosApp
      .get(`${process.env.REACT_APP_API_BASE_URL}/verifyToken/${isAdmin}`, { withCredentials: true })
      .then(async response => {
 
        setUser({ ...response.data.user })
        setAuth(response.data.auth)
 
      })
  }

  useEffect(() => {
    isAuthenticated()

 
  }, [])

  function getLayout() {
    let layoutCls = HorizontalLayout
    // switch (props.layout.layoutType) {
    //   case "horizontal":
    //     layoutCls = HorizontalLayout
    //     break
    //   default:
    //     layoutCls = VerticalLayout
    //     break
    // }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Router>
        <UserContext.Provider
            value={{ user, setUser, auth, setAuth, isAuthenticated }}
          >
<Switch>
       
            {publicRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={auth}
                shouldBeAuth={false}
                exact
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={auth}
                shouldBeAuth={true}
                exact
              />
            ))}{" "}
            </Switch>
        </UserContext.Provider>

      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
