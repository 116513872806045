import React, {useState, useEffect} from "react";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'


import axios from 'axios'



function SingleProduct(props) {

    const[productCompletion, setProductCompletion] =  React.useState(0);

    React.useEffect(()=>{
    
        var result = getDuration(props.product.planid, props.product.product_id)
        console.log("Planid return ", result, props.product.planid)
    
      },[props.product])

    var colorTypes = {
        16: "#3376b3",
        17: "#3376b3",
        15: "rgb(140, 139, 140)",
        18: "#3376b3",
        19: "#3376b3",
        20: "#3376b3",
    }

    var productIcons = {
        savings: faWallet,
        vault: faWallet,
        16: faWallet,
        17: faWallet,
        15: faWallet,
        18: faWallet,
        19: faWallet,
        20: faWallet,
    }

    var productTitles = {
        16: 'Savings Vault',
        17: 'Savings Vault',
        15: 'Transaction Accumulation Fund',
        18: 'Inflation Counter Index',
        19: 'Inflation Counter Index',
        20: 'Inflation Counter Index',

        22: 'Capital Interest Vault',
        23: 'Capital Interest Vault',
        24: 'Capital Interest Vault',
        25: 'Capital Interest Vault',
        26: 'Savings Bundle',
        27: 'Savings Bundle',
        28: 'Savings Bundle',
        29: 'Tokenized Index',
        30: 'Tokenized Index',
        31: 'Tokenized Index',
        32: 'Tokenized Index',
        33: 'Monetary Stability Fund',
        34: 'Monetary Stability Fund',
        35: 'Monetary Stability Fund',
        36: 'Synthetic Assets',
        37: 'Synthetic Assets',
        38: 'Synthetic Assets',
    }

    var status = {
        0:  'Pending',
        1: 'Open',
        3: 'Closed',

    }

    var currency = {
        '1': 'USDT',
        '2': 'ZAR',
        '3': 'BTC',
    }

    var duration = {
        16: 6,
        17: 12,
        15: 6,
        18: 3,
        19: 6,
        20: 12,
    }

    

    const getDuration = async(planid, productid) => {

        var total = 0;  
        

        var planidObj = {
            planid: planid
        }

        console.log("total months ", planid)
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-product-interest-month`,planidObj).then(async (res)=>{
            if(res.data.status == 200)
            {
           
                console.log(res.data.total)
              total = res.data.total * 100 / duration[productid]
              console.log("total months ", total)
              setProductCompletion(total)
            }else{
                console.log("notging... ")
                total = 0; 
                setProductCompletion(total)
            }
          })
        

          return total;
    }

   

    var iconType = productIcons[props.product.type];
  return (
    <a href={"/single-product?pid=" + props.product.id} >
    <div className="rounded-lg  single-product-container inline-block ml-1 mr-1" style={{backgroundColor: "#3376b3"}}>
        <span><FontAwesomeIcon icon={faWallet} size="xl" className='inline-block text-center' /> 
        <h3 className='inline-block ml-3 text-left mb-3 underline font-semibold'>{productTitles[props.product.product_id]} ({status[props.product.status]})</h3></span>
        <div className='mt-4'>

        

            <div className="w-full bg-gray-200 mb-4 rounded-full dark:bg-gray-700">
                <div className="primary-bg text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width: productCompletion  + "%"}}> { productCompletion}%</div>
            </div>

            <h2 className='text-white-800 font-semibold'>{currency[props.product.currency] + ' ' + props.product.amount}</h2>
            <p className='text-white text-xs'>{props.product.planid}</p>

       </div>
    </div>
    </a>

  )
}

export default SingleProduct