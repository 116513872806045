import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from "react-datepicker";

import Dropzone from "react-dropzone"

import "react-datepicker/dist/react-datepicker.css";

import CountryDropdown from 'country-dropdown-with-flags-for-react';  

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback,
    Nav,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    InputGroup,
    ModalBody

  } from "reactstrap";

  import { Link } from "react-router-dom"


function Settings() {

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');
    const[userType, setUserType] =  React.useState('0');

    const[userUserid, setUserUserid] =  React.useState('');

    const queryParams = new URLSearchParams(window.location.search);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #3376b3',
      };
    React.useEffect(()=>{
    
        if(auth && user)
        {
          getUserDetails(user.user.userid)
          setUserid(user.user.userid)
          setUserUserid(user.user.userid)
          setUserType(user.user.usertype)
    
        }else{
            console.log("Not logged in");
    
        }
    
      },[auth])

      const getUserDetails = async (userid) => {
        var userObj = {
            userid: userid
          }
      
          await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`,userObj).then(async (res)=>{
            if(res.data.status == 200)
            {
                console.log(res.data.user);
                setName(res.data.user.name);
                setLastName(res.data.user.lastname);
                setCell(res.data.user.cell);
                setEmail(res.data.user.email);
                setOTP(res.data.user.otp_type)

                setIDNumber(res.data.info.id_num);
                setAddress(res.data.info.address);
                setSourceIncome(res.data.info.sourceFunds);
                if(res.data.info.dob !== '')
                    setDOB(new Date(res.data.info.dob))
                // setDOB(res.data.info.dob);
                setCountry(res.data.country)

                if(res.data.user.fica_status === 'A' || res.data.user.fica_status === 'P')
                {
                    setFicaCompleted(true)
                }
        
            }else{
              console.log(res.data)
      
            }
          })
      
          return true;
      }

    const[name, setName] =  React.useState('');
    const[lastname, setLastName] =  React.useState('');
    const[cell, setCell] =  React.useState('');
    const[email, setEmail] =  React.useState('');
    const[otp, setOTP] =  React.useState('email');

    // KYC Fields
    const[idnumber, setIDNumber] =  React.useState('');
    const[address, setAddress] =  React.useState('');
    const[sourceIcone, setSourceIncome] =  React.useState('');
    const[idupload, setidupload] =  React.useState('');
    const[poaupload, setPOAUpload] =  React.useState('');
    const[dob, setDOB] =  React.useState('');
    const[dobSave, setDOBSave] =  React.useState('');

    const[cipc, setCipc] =  React.useState('');

    
    const[ficaCompleted, setFicaCompleted] =  React.useState(false);

    const[autoRenew, setAutoRenew] =  React.useState(true);
    const[autoRenewBalance, setAutoRenewBalance] =  React.useState(true);

    

    const [country, setCountry] = React.useState('South Africa');
    


    const onIDChange = (id) => {
        setIDNumber(id)
    }

    const onAddressChange = (address) => {
        setAddress(address)
    }

    const onCourceIncomeChange = (income) => {
        setSourceIncome(income)
    }

    const onIDUpload = (id) => {
        setidupload(id)
    }

    const onPOAUpload = (upload) => {
        setPOAUpload(upload)
    }


    const onNameChange = (name) => {
        setName(name)
    }

    const onLastNameChange = (lastname) => {
        setLastName(lastname)
    }


    const handleRadioButtonChange = (value) => {
        setOTP(value)
    }

    
    const handleOnAutoRenew = (value) => {

        console.log(value)
        var isTrueSet = (value === 'true');
        if(!isTrueSet)
            setAutoRenewBalance(isTrueSet)

        setAutoRenew(isTrueSet)
    }

    
    const handleWalletBalanceAuto = (value) => {
        var isTrueSet = (value === 'true');
        setAutoRenewBalance(isTrueSet)
    }



    const onEmailChange = (email) => {
        setEmail(email)
    }

    const onCellChange = (cell) => {
        setCell(cell)
    }

    const onDOBChange = (dob) => {
   
        var dateObj = new Date(dob);
       
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
      
        var day = dateObj.getUTCDate();

      
        var year = dateObj.getUTCFullYear();

       

        if(month < 10)
        {
            month = '0' + month.toString();
        }

        if(day < 10)
        {
            day = '0' + day.toString();
        }

        var newdate = year + "-" + month + "-" + day;

        console.log('dateobj', newdate)
        // console.log(newdate)
        setDOBSave(newdate)
        setDOB(new Date(newdate))
    }

    const onSettingsSave = async (settings) => {
        console.log("Settings Save Clicked")

        var userObj = {
            userid: userid,
            name: name,
            lastname: lastname,
            // email: email,
            cell: cell,
            otp: otp,
            autoRenew: autoRenew,
            autoRenewBalance: autoRenewBalance
          }
      
        var userUpdate = await axios.post(process.env.REACT_APP_API_BASE_URL+"/update-user-settings",userObj).then(async res =>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                toast.success('Updated User Details!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                toast.error('Unable to Update User Details', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
        })
    }

    const onCIPCChange = (cipc) => {
        setCipc(cipc)
    }

    const onKYCSave = async (settings) => {
        console.log("KYC Save Clicked")

        if(country == '' )
        {
            toast.error('Please select a country!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(sourceIcone == '' )
        {
            toast.error('Please select Source Of Income!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(dobSave == '' )
        {
            toast.error('Please select Date of Birth!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(idnumber == '' )
        {
            toast.error('Please enter ID Number', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }


        
        if(address == '' )
        {
            toast.error('Please enter Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        

        // if(typeof idupload[0] === 'undefined' || userficaupload.length === 0)
        // {
        //     toast.error('Please Upload ID Document', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //         });
        // }

        // if(typeof poaupload[0] === 'undefined' || poaupload.length === 0)
        // {
        //     toast.error('Please Upload Proof Of Address', {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "dark",
        //         });
        // }

        console.log("Uploads is ", useridUpload)
        console.log("country is is ", country)
        // const [useridUpload, setuseridUpload] = useState([])
        // const [userpoaupload, setuserpoaupload] = useState([])
        // const [userficaupload, setuserficaupload] = useState([])

        var formD = new FormData();
        formD.append('userid', userid)
        formD.append('country',country)
        formD.append('sourceFunds',sourceIcone)
        formD.append('dob',dobSave)
        formD.append('idNumber',idnumber)
        formD.append('address',address)
        formD.append("idupload",useridUpload[0])
        formD.append("poaupload",userpoaupload[0])
        if(userType == '1' || userType == 1)
        {
            formD.append("cipc",cipc)
          if(typeof userficaupload[0] !== 'undefined' && userficaupload.length !== 0)
          {
            formD.append("userficaupload",userficaupload[0])
        
          }else{
 
            toast.error('Require CIPC Document!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            return false
          }

          if(cipc == '')
          {
              toast.error('Please enter CIPC Nr', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });

                  return false;
          }

        }


        await axios.post(process.env.REACT_APP_BASE + "api/update-ficadata",formD).then(async res =>{
        
          if(res.data.status == 200)
          {
            // toastr.options.onHidden = function() { window.location.href="/dashboard" } 
            //   toastr.success(res.data.message) 
              
            toast.success(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                const getUserToken = queryParams.get('complete');

                if(getUserToken !== '' && getUserToken !== null && getUserToken === true) {
                    window.location.href="/deposit" 
                }else{
                    window.location.href="/dashboard"
                }
              
            //   setKYCStep(kycStep + 1)
          }
          else
            {
              
            toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
              return false
            }
        });
    }


    // Uploads
    const [useridUpload, setuseridUpload] = useState([])
    const [userpoaupload, setuserpoaupload] = useState([])
    const [userficaupload, setuserficaupload] = useState([])
    const [selectedFiles, setselectedFiles] = useState([])

    function handleAcceptedFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
        setselectedFiles(files)
        setuseridUpload(files)
        
      }

      function handleAcceptedPOAFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
      
        setuserpoaupload(files)
        
      }

      function handleAcceptedFicaFiles(files) {
        console.log(files)
        console.log(URL)
        files.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size)
          })
        )
      
        setuserficaupload(files)
        
      }

      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }

    //   Passwords
    
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    
    const [modalOTP, setModalOTP] = useState('')

    function onCurrentPasswordChange(password) {
        setCurrentPassword(password)   
    }

    function onNewPasswordChange(password) {
        setNewPassword(password)   
    }

    function onConfirmPasswordChange(password) {
        setConfirmPassword(password)   
    }

    const onPasswordSave = async() => {
        var userid = userUserid;
        var newPasswordSave = newPassword;
        var currentPasswordSave = currentPassword;
        var confirmPasswordSave = confirmPassword;

        if(confirmPasswordSave !== newPasswordSave) {
            return toast.error("Passwords Don't Match", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

        var updatePasswordObj = {
            userid: userid,
            newPassword: newPasswordSave,
            currentPassword: currentPasswordSave
        }

        console.log("The updated password is  ", updatePasswordObj)

        await axios.post(process.env.REACT_APP_BASE + "api/update-password",updatePasswordObj).then(async res =>{
        
            if(res.data.status == 200)
            {
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
                toast.success("Updated Password Successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
            else
                toast.error("Unable To Update Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
        });
    }

    const onEmailSave = async() => {
        setModalDisplay(true)
        await sendOTP()
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userUserid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
            console.log(res.data)
      if(res.data.status == 200)
      {
    
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }

    const updateEmail = async() => {
        var userObj = {
            userid: userUserid,
            email: email,
          }
      
        var userUpdate = await axios.post(process.env.REACT_APP_API_BASE_URL+"/update-user-email",userObj).then(async res =>{
            console.log(res.data)
            if(res.data.status == 200)
            {
                toast.success('Updated User Email!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }else{
                toast.error('Unable to Update User Email', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
            }
        })
    }

    const [modalDisplay, setModalDisplay] = useState(false)

    const handleAccept = async() => {
        setModalDisplay(false)

        
        var otpObj = {
            userid: userUserid,
            otp: modalOTP
          }

        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                  
            if(res.data.status !== 200)
            {

                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
                    setModalOTP('')
                    setModalDisplay(true)
                    await sendOTP();

                return false;
            }else{
                setModalOTP('');
                return true;
            }
        });

        if(!checkOtp)
            return false;

        if(checkOtp)
        {
            await updateEmail()
        }
    }

    const closeModal = async() => {
        setModalDisplay(false)
    }

    const onModalOTPChange = async (otp) => {
        setModalOTP(otp)
    }
    

  return (
    <div className="container mx-auto px-2 pt-8 pb-8 ">

<ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
    <Modal
      open={modalDisplay}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"

      className="form-no-background"
    >
      <Box 
      sx={{
        ...style, width: 400,
        background:
          "	rgba(5,0,5,0.8)"
      }}>
        <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>OTP: </h2>
        
        <input type="number" value={modalOTP} onChange={(e) => onModalOTPChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />

        <Button style={{    
                background: "#040E18",
                borderRadius: "70px",
                border: "2px solid #3376b3",
                color: "#fff"}} className='c1-primary-btn' onClick={handleAccept}>Confirm</Button>

<Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>

        </Box>

    </Modal>
    
<h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-2xl text-white">Settings</h1>
    <div className="container-md mx-auto px-4 form-no-background">

            

        <form>
        <div className="container-md px-4 p-3 ">

        <div>
       
           <div className="mb-6 mt-6 ">
        <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Email</h1>
        <small className="text-white">Your email address is your identity on Nessfin and is used to log in.</small>
        </div>
 
           <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                {userType === '1' ? 'Company ' : ''}Email 
                </label>
                <input type="text" value={email} onChange={(e) => onEmailChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>


            <div className="flex items-center justify-between mb-2">
                <button  onClick={(e) => onEmailSave(e.target.value)} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Save
                </button>
           </div>

           </div>

           <br /><hr /><br />


        <div className="mb-6 mt-6 ">
        <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Profile</h1>
        </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    {userType === '1' ? 'Company ' : ''} Name 
                </label>
                <input type="text" value={name} onChange={(e) => onNameChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>

            {userType !== '1' ? 
            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    LastName 
                </label>
                <input type="text" value={lastname} onChange={(e) => onLastNameChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>
            : ''}



            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                {userType === '1' ? 'Company ' : ''}Cell 
                </label>
                <input type="text" value={cell} onChange={(e) => onCellChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    OTP Option 
                </label>
                
                <input onClick={(e) => handleRadioButtonChange(e.target.value)} type="radio" checked={otp === 'email'}  className="btn-check" name="vbtn-radio"  value="email" id="allOption" />
                <label className={'c1-primary-radio mr-8' + (otp === 'email' ? ' c1-primary-btn-active' : '')} htmlFor="allOption">Email</label>
                <input onClick={(e) => handleRadioButtonChange(e.target.value)} checked={otp === 'sms'}  type="radio" className="btn-check" name="vbtn-radio" value="sms" id="seperateOption"/>
                <label className={'c1-primary-radio mr-8' + (otp === 'sms' ? ' c1-primary-btn-active' : '')} htmlFor="seperateOption">SMS</label>
                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    AutoRenew Products <small className="text-danger">(System Will automatically renew products when your contract period expires.)</small>
                </label>
                
                <input onClick={(e) => handleOnAutoRenew(e.target.value)} type="radio" checked={autoRenew === true}  className="btn-check" name="autorenew-radio"  value={true} id="yesauto" />
                <label className={'c1-primary-radio mr-8' + (autoRenew === true ? ' c1-primary-btn-active' : '')} htmlFor="yesauto">Yes</label>
                <input onClick={(e) => handleOnAutoRenew(e.target.value)} checked={autoRenew === false}  type="radio" className="btn-check" name="autorenew-radio" value={false} id="noauto"/>
                <label className={'c1-primary-radio mr-8' + (autoRenew === false ? ' c1-primary-btn-active' : '')} htmlFor="noauto">No</label>      
            </div>

            {autoRenew === true ?
            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Use Wallet Balance for auto renewal <small className="text-muted">(Wallet Balance will be renewed when product end date is reached.)</small>
                </label>
                
                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} type="radio" checked={autoRenewBalance === true}  className="btn-check" name="autorenewbalance-radio"  value={true} id="yesautobalance" />
                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === true ? ' c1-primary-btn-active' : '')} htmlFor="yesautobalance">Yes</label>
                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} checked={autoRenewBalance === false}  type="radio" className="btn-check" name="autorenewbalance-radio" value={false} id="noautobalance"/>
                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === false ? ' c1-primary-btn-active' : '')} htmlFor="noautobalance">No</label>      
            </div>
            : ''}

            <div className="flex items-center justify-between mb-2">
                <button  onClick={(e) => onSettingsSave(e.target.value)} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Save
                </button>
           </div>

           <br /><hr /><br />

           <div>
           <h1 className="mb-6 mt-6 text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white">Password</h1>
 
           <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Current Password 
                </label>

                <input type="password" value={currentPassword} onChange={(e) => onCurrentPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    New Password 
                </label>

                <input type="password" value={newPassword} onChange={(e) => onNewPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>
            
            
            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Confirm Password 
                </label>

                <input type="password" value={confirmPassword} onChange={(e) => onConfirmPasswordChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>

            <div className="flex items-center justify-between mb-2">
                <button  onClick={(e) => onPasswordSave(e.target.value)} className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Save
                </button>
           </div>

           </div>

           <br /><hr /><br />
            <div className="mb-6 mt-6 ">
                <h1 className="text-1l font-extrabold leading-none tracking-tight md:text-1l lg:text-1xl text-white"  id="kycfields">KYC</h1><span className='text-red-500 text-sm'>(Please note that these fields can't be changed once Saved and will require assistance from Admin to be changed)</span>
            </div>
           
            
            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    IDNumber 
                </label>
                <input type="text" value={idnumber} onChange={(e) => onIDChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                {userType === '1' ? 'Director ' : ''}Date Of Birth 
                </label>
                {/* <input                  
                    name="dob"
                    placeholder="yyyy-mm-dd"
                    
                    type="date"
                    onChange={(e)=>setDOB(e.target.value)}
                    value={dob} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" 
                    /> */}

                    <DatePicker dateFormat="yyyy-MM-dd" selected={dob} onChange={(date) => onDOBChange(date) }  className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>
            
            
            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Address 
                </label>
                <input type="text" value={address} onChange={(e) => onAddressChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>


            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Source Of Income 
                </label>
                <select value={sourceIcone} onChange={(e) => onCourceIncomeChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline">
                    <option value="Savings">Savings</option>
                    <option value="Pension Fund">Pension Fund</option>
                    <option value="Sale of shares">Sale of shares</option>
                    <option value="Sale of property">Sale of property</option>
                    <option value="Inheritance">Inheritance</option>
                    <option value="Dividends">Dividends</option>
                    <option value="Trading">Trading</option>
                    <option value="Winnings">Winnings</option>
                    <option value="Compensation">Compensation</option>
                    <option value="Other">Other</option>
                </select>

                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    Select Country
                </label>
            <CountryDropdown  id="countryID" className='form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 form-no-background leading-tight focus:outline-none focus:shadow-outline' preferredCountries={['za', 'us']}  value=""  handleChange={e => setCountry(e.target.value)} ></CountryDropdown> 
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                {userType === '1' ? 'Director ' : ''} ID Upload
                </label>

                {ficaCompleted? 
                <p className="text-green-500">Documents already uploaded!</p>
                :
                <div>
                                        <div className="dropzone-previews mt-3" id="file-previews">
                                        {useridUpload.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        </div>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} type="file" name="userUpload" />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h4>Drop Proof of ID here or click to upload.</h4>
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                                        <br />
                                        </div> }
                       
            </div>

            <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                {userType === '1' ? 'Director ' : ''}Proof of Address Upload
                </label>
                {ficaCompleted? 
                <p className="text-green-500">Documents already uploaded!</p>
                :
                <div>
                    <span className='text-red-500 text-sm'>(Not older than 3 months)</span>
                <div className="dropzone-previews mt-3" id="file-previews">
                                        {userpoaupload.map((f, i) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={i + "-file"}
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                        </div>
                                        <Dropzone
                                        onDrop={acceptedFiles => {
                                            handleAcceptedPOAFiles(acceptedFiles)
                                        }}>
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} type="file" name="userUpload" />
                                                <div className="dz-message needsclick">
                                                    <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                    </div>
                                                    <h4>Drop files here or click to upload.</h4>
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                </div>
                }
                       
            </div>
            

            {userType === '1' ?
            <div>
                <div className="mb-6">
                    <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                        CIPC Documents </label>
                                            <div>
                                        
                                            {ficaCompleted? 
                    <p className="text-green-500">Documents already uploaded!</p>
                    :
                    <div>
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                            {userficaupload.map((f, i) => {
                                                return (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                >
                                                    <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            height="80"
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f.name}
                                                            src={f.preview}
                                                        />
                                                        </Col>
                                                        <Col>
                                                        <Link
                                                            to="#"
                                                            className="text-muted font-weight-bold"
                                                        >
                                                            {f.name}
                                                        </Link>
                                                        <p className="mb-0">
                                                            <strong>{f.formattedSize}</strong>
                                                        </p>
                                                        </Col>
                                                    </Row>
                                                    </div>
                                                </Card>
                                                )
                                            })}
                                            </div>
                                            <Dropzone
                                            onDrop={acceptedFiles => {
                                                handleAcceptedFicaFiles(acceptedFiles)
                                            }}>
                                                {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                    <div
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                    >
                                                    <input {...getInputProps()} type="file" name="userUpload" />
                                                    <div className="dz-message needsclick">
                                                        <div className="mb-3">
                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                    </div>
                                                </div>
                                                )}
                                            </Dropzone>
                                            </div>}
                </div>
                </div>

                <div className="mb-6">
                <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="password">
                    CIPC Number
                </label>
                <input type="text" value={cipc} onChange={(e) => onCIPCChange(e.target.value)} className="form-control form-no-background shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline" />
                       
            </div>
            </div>

            : ''}

            {!ficaCompleted? 
            <div className="flex items-center justify-between">
                <button onClick={(e) => onKYCSave(e.target.value)}  className="c1-primary-btn  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                    Save
                </button>
           </div>
           : ''}
  
        </div>
        </form>



    </div>
   


    </div>
  )
}

export default Settings