import React, {useState, useEffect} from "react";

// Import Single Product Component
import SingleProduct from './SingleProduct'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { UserContext } from "../../../UserContext";
import axios from 'axios'

var Carousel = require('react-responsive-carousel').Carousel;


// var products = [
//     {
//         title: "Savings",
//         status: "Open",
//         created: "12 Jan 2022",
//         expire: "12 March 2022",
//         type: "savings",
//         amount: "1 500.00",
//         plan: "#C1-44242424",
//         percentageComplete: 40
//     },
//     {
//         title: "Vault",
//         status: "Closed",
//         created: "12 Jan 2022",
//         expire: "12 March 2022",
//         type: "vault",
//         amount: "2 500.00",
//         plan: "#C1-4575828282882",
//         percentageComplete: 100
//     },
//     {
//         title: "Savings",
//         status: "Open",
//         created: "12 Jan 2022",
//         expire: "12 March 2022",
//         type: "savings",
//         amount: "1 000.00",
//         plan: "#C1-145757528828",
//         percentageComplete: 60
//     },
//     {
//         title: "Vault",
//         status: "Closed",
//         created: "12 Jan 2022",
//         expire: "12 March 2022",
//         type: "vault",
//         amount: "500.00",
//         plan: "#C1-5641515155",
//         percentageComplete: 100
//     }
// ]

function ProductsContainer() {

    const{auth,user} = React.useContext(UserContext)
    const[displayNotifications, setDisplayNotifications] =  React.useState(true);
    const[products, setProducts] =  React.useState([]);

    const toggleNotification = (toggle) => {

        setDisplayNotifications(!displayNotifications);
        console.log(displayNotifications)
    }

    React.useEffect(()=>{
    
        if(auth && user)
        {
          getUserProducts(user.user.userid)
    
        }else{
            console.log("Not logged in");
    
        }
    
      },[auth])
    
      const getUserProducts = async(userid) =>{
        var userObj = {
          userid: userid
        }
    
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-all-user-products`,userObj).then(async (res)=>{
          if(res.data.status == 200)
          {
            console.log("Products ", res.data.Products)
            setProducts(res.data.Products)
            
          }else{
            console.log(res.data)
    
          }
        })
    
        return true;
      }
    

  return (
    <div>
        <div>My <span className="primary-color">Products <FontAwesomeIcon onClick={(e)=> {toggleNotification(e.target.value)}} className="pl-2" icon={faCaretDown} size="xl"  /></span></div>
        {displayNotifications ?
        <div className="mt-6 grid grid-cols-4 gap-4 dashboard-grid-container">
            {typeof products !== 'undefined' && products.length > 0 ?
                products.map((product,key) => (
                    <SingleProduct key={key} product={product} />
                ))
            : "No Products"
            }
         
        </div>
        : ''}
    </div>
  )
}

export default ProductsContainer