import React from "react"
import { Redirect } from "react-router-dom"

// publicRoutes
import Login from "../pages/Authentication/Login"
import Register from "../pages/Authentication/Register"
import ConfirmEmail from "../pages/Authentication/ConfirmEmail"

// authProtectedRoutes
// ===============================================================================
import Dashboard from "../pages/Dashboard/dashboard"

// Products
import AccumulationFund from "../pages/Products/AccumulationFund"
import InflationCountIndex from "../pages/Products/InflationCountIndex"
import SavingsVault from "../pages/Products/SavingsVault"

// Transactions
import Deposit from "../pages/Transactions/Deposit"
import Withdraw from "../pages/Transactions/Withdraw"
import Exchange from "../pages/Transactions/Exchange"
import History from "../pages/Transactions/History"
import Notifications from "../pages/Notifications/Notifications"
import Settings from "../pages/Settings/Settings"

// Transactions
import Reports from "../pages/Reports/Report"
import ForgotPassword from "../pages/Authentication/forgotPassword"
import UpdatePassword from "../pages/Authentication/UpdatePassword"
import SingleProduct from "../pages/Products/SingleProduct"
import ProductsPage from "../pages/Products/ProductsPage"
import Error404 from "../pages/404/Error404"

// ===============================================================================

  const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard},
    { path: "/accumulation-fund", component: AccumulationFund},
    { path: "/inflation-counter-index", component: InflationCountIndex},
    { path: "/savings-vault", component: SavingsVault},
    { path: "/deposit", component: Deposit},
    { path: "/withdraw", component: Withdraw},
    { path: "/exchange", component: Exchange},
    { path: "/transactions", component: History},
    { path: "/reports", component: Reports},
    { path: "/notifications", component: Notifications},
    { path: "/settings", component: Settings},
    { path: "/single-product", component: SingleProduct},
    { path: "/products/:product", component: ProductsPage},
    
    
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
    { path: "*", exact: true, component: () => <Redirect to="/not-found" /> },
  ]
    
    const publicRoutes = [
        { path: "/login", component: Login },
        { path: "/register/:company", component: Register },
        { path: "/forgot-password", component: ForgotPassword },
        { path: "/reset-password", component: UpdatePassword },
        { path: "/confirm-email", component:ConfirmEmail},
        { path: "/not-found", component:Error404}
    ]
    
    export { authProtectedRoutes, publicRoutes }