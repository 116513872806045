import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logoGif from "../../assets/uploads/logo_gif.png"

import { withRouter, Link, useLocation } from "react-router-dom"

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

function UpdatePassword() {



    let query = useQuery()
    let userToken = query.get("userToken");

    const[password, updatePassword] =  React.useState('');
    const[cPassword, updateCPassword] =  React.useState('');
    const [alreadySentFirstEmail, setAlreadySentFirstEmail] = useState(false)

    const submitButton = async() => {

        await axios.post(process.env.REACT_APP_API_BASE_URL + `/reset-password`,{password: password, userToken}).then((res)=>{
           
            if (res.data.status == 200) {

              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                setTimeout(() => {window.location.href = "/login"; }, 2000);
         
            } else {
              
              toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
          })

    }


  return (
    <React.Fragment>
    <div className="website-main-bg rounded-xl shadow border h-screen">
    <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div className="px-6 h-full text-white-800">
        <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

          <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >

            <img
              src={logoGif}
              className="w-full"
              alt="Sample image"
            />
          </div>

          <div style={{padding: "20px", borderRadius: "15px"}} className="website-light-dark-bg xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
            <form>

            <div className="mb-4">
              <h1 className="text-white text-center text-4xl">Reset Password</h1>
            </div>
 
              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Password"
                  onChange={(e) => {updatePassword(e.target.value)}}
                 
                  value={password}
                />
              </div>

              <div className="mb-6">
                <input
                  type="password"
                  className="form-control block w-full px-4 py-2 text-xl font-normal text-black bg-white bg-clip-padding border border-solid border-white-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-blue-600 focus:outline-none"
                  id="exampleFormControlInput2"
                  placeholder="Confirm Password"
                  onChange={(e) => {updateCPassword(e.target.value)}}
                 
                  value={cPassword}
                />
              </div>


              <div className="text-center lg:text-left">
                <button type="button" className="website-primary-btn " onClick={(e) => {submitButton(e)}}>
                  Submit
                </button>

                </div>

            </form>
          </div>
          
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

export default UpdatePassword