import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

function Wallet() {

  const{auth,user} = React.useContext(UserContext)

  const [userBalance, setUserBalance] = useState({zar: 0, btc: 0, usdt: 0})
  const [userUSDT, setUSDT] = useState(0)
  const [userZAR, setZAR] = useState(0)
  const [userBTC, setBTC] = useState(0)

  React.useEffect(()=>{
    
    if(auth && user)
    {
      getUserBalance(user.user.userid)

    }else{
        console.log("Not logged in");

    }

  },[auth])

  const getUserBalance = async(userid) =>{
    var userObj = {
      userid: userid
    }

    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
      if(res.data.status == 200)
      {
        console.log(res.data)
        setZAR(res.data.currencyInfo[1].balance)
        setUSDT(res.data.currencyInfo[0].balance)
        setBTC(res.data.currencyInfo[2].balance)
      }else{
        console.log(res.data)

      }
    })

    return true;
  }


  return (
    <div className=" md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0" id="navbar-collapse">
        <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
            ZAR {userZAR}
        </p>
        <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
            USDT {userUSDT}
        </p>
        <p href="#" className="p-2 lg:px-2 md:mx-1 primary-color rounded transition-colors duration-300">
            BTC {userBTC}
        </p>
    </div>
  )
}

export default Wallet