import PropTypes from "prop-types";

import React, {useState, useEffect} from "react";

import '../../index.css';

import { withRouter, Link, Redirect    } from "react-router-dom";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Row, Col, CardBody, Card, Alert, Container, Form, Modal, Input, ModalBody, FormFeedback, Label, Button } from "reactstrap";

import logoGif from "../../assets/uploads/logo_gif.png"

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogleDrive } from '@fortawesome/free-solid-svg-icons'


import { UserContext } from "../../UserContext";

const Login = props => {

  const queryParams = new URLSearchParams(window.location.search);

  const[isUserRedirected, setIsUserRedirected] =  React.useState(false);

  const{auth,user} = React.useContext(UserContext)

  


  React.useEffect( () => {
  
    if (auth != null) {
      if (auth) {
        window.location.href="/dashboard"
      }else{
        const getUserToken = queryParams.get('complete');


        if(getUserToken !== '' && getUserToken !== null) {
          console.log("getusertoken is " + getUserToken)
          setIsUserRedirected(true)
        }


      }


    }

    const geErrorToken = queryParams.get('error');

  if(geErrorToken !== '' && geErrorToken !== null) {
    
    toast.error('Unable To Login, Please try again later', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }

  console.log(geErrorToken)


  }, [auth])

  const[email, setEmail] =  React.useState('');
  const[password, setPassword] =  React.useState('');


  const onLoginClick = () => {
    console.log("User Clicked login")

    var formD = new FormData();
    formD.append('username',email)
    formD.append('password',password)
    formD.append('isAdmin',true)
    // setLoader(true)
    axios.create({
      
    })

    axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`,formD,{withCredentials:true}).then((res)=>{

      if(res.data.status == 200)
      {
        // It is successfull client is logged in
        // if(res !== undefined){
        //   // toastr.success(res.data.message)
        // }
        if(res.data.auth)
        {

          if(isUserRedirected)
            window.location.href="/settings#kycfields?complete=true" 
          else
            window.location.href="/dashboard" 

        }
      }else{
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      // else{
      //   // toastr.error(res.data.message)
      // }

    })

    // props.history.push('/dashboard')
  }


  const changePassword = (password) => {
    setPassword(password);
  }

  const changeEmail = (email) => {
    console.log(email)
    setEmail(email);
  }


  return (
    <React.Fragment>

<ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
      <div className="website-main-bg rounded-xl shadow border h-screen">
        <div className="px-6 h-full website-text-color-800">
          <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">

            <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0" >
              <img
                src={logoGif}
                className="w-full"
                alt="Sample image"
              />
            </div>

            <div style={{padding: "20px", borderRadius: "15px"}} className="website-light-dark-bg xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
              <Form>

                {!isUserRedirected?
                <div>
                <div className="flex flex-row items-center justify-center lg:justify-start">
                  {/* <p className="text-lg website-text-color mb-0 mr-4">Sign in with</p> */}
                  {/* <Button
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="facebook inline-block p-3  website-text-color font-medium text-xs leading-tight uppercase rounded-full shadow-md  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-1"
                  >
                    <FontAwesomeIcon icon={brands('facebook-f')} size="xl"  />
              
                  </Button> */}

                  {/* <Button
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="twitter inline-block p-3  website-text-color font-medium text-xs leading-tight uppercase rounded-full shadow-md  focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-1"
                  >
             
                  <FontAwesomeIcon icon={brands('twitter')} size="xl" />
                  </Button> */}

                  {/* <a
                  href="https://api.nessfinassets.io/auth/google"
                    type="button"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    className="google inline-block p-3  website-text-color font-medium text-xs leading-tight uppercase rounded-full shadow-md  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mx-1"
                  >
              
                    Google
                  </a> */}
                </div>

                <div
                  
                  className="website-light-dark-bg flex items-center my-4 before:flex-1 before:border-t before:border-white-300 before:mt-0.5 after:flex-1 after:border-t after:border-white-300 after:mt-0.5"
                >
                  <p className="text-center font-semibold text-white mb-0">Welcome Back</p>
                </div>
                </div>
                : ''}


                <div className="mb-6">
                  <input
                    onChange={(e) => {changeEmail(e.target.value)}}
                    value={email}
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal website-text-light-bg website-text-color  border border-solid border-white-300 rounded transition ease-in-out m-0"
                    id="exampleFormControlInput2"
                    placeholder="Email address"
                  />
                </div>


                <div className="mb-6">
                  <Input
                    onChange={(e) => {changePassword(e.target.value)}}
                    value={password}
                    type="password"
                    className="form-control block w-full px-4 py-2 text-xl font-normal website-text-color website-text-light-bg border border-solid border-white-300 rounded transition ease-in-out m-0"
                    id="exampleFormControlInput2"
                    placeholder="Password"
                  />
                </div>

                <div className="flex justify-between items-center mb-6">
                  {/* <div className="form-group form-check">
                    <Input type="checkbox" className="form-check-input appearance-none h-4 w-4 border border-white-300 rounded-sm bg-white checked: checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" id="exampleCheck2" />
                    <label className="form-check-label inline-block website-text-color website-text-color-800" for="exampleCheck2">Remember me </label>
                  </div> */}
                  <a href="/forgot-password" className="website-text-color-800 text-white">Forgot password?</a>
                </div>

                <div className="text-center lg:text-left">
                  <Button onClick={onLoginClick} type="button" className="website-primary-btn ">
                    Login
                  </Button>

                  
                  {/* <p className="text-sm font-semibold mt-2 pt-1 mb-0 website-text-color">Don't have an account?<a href="/register" className="text-red-600 hover:text-red-700 focus:text-red-700 transition duration-200 ease-in-out">Register</a></p> */}
                </div>

              </Form>
            </div>
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
