import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionHandleModal from '../Containers/Transactions/TransactionHandleModal';
import TransactionHandleToModal from '../Containers/Transactions/TransactionHandleToModal';
import TransactionHandleConfirmationModal from '../Containers/Transactions/TransactionHandleConfirmationModal';


function TransactionModal({modalDisplay, closeModal, modalOTP, handleAccept, title}) {

    
    const [otp, setOTP] = React.useState('');

    const [steps, setSteps] = React.useState(1);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    React.useEffect(()=>{
    
      if(auth && user)
      {
          console.log("Logged in ", auth, user);
          setUserid(user.user.userid)
      }else{
          console.log("Not logged in");
  
      }
    },[auth])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        border: '2px solid #3376b3',
      };

      const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
            console.log(res.data)
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }

      const nextStep = async () => {
        if(steps === 1 && modalOTP["type"] !== "exchange")
        {
          await sendOTP()
        }

        if(steps === 2 && modalOTP["type"] === "exchange")
        {
          await sendOTP()
        }

        if(steps === 1 && modalOTP["type"] === "exchange")
            setSteps(steps + 1)
        else if(modalOTP["type"] === "exchange")
            setSteps(steps + 1)
        else
            setSteps(steps + 2)

      }

      const PreviousStep = () => {

        if(steps === 1 && modalOTP["type"] === "exchange")
            setSteps(steps - 1)
        else if(modalOTP["type"] === "exchange")
            setSteps(steps - 1)
        else
            setSteps(steps - 2)
      }


  return (
    <div>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />

    <Modal
      open={modalDisplay}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"

      className="form-no-background"
    >
      <Box 
      sx={{
        ...style, width: 400,
        background:
          "	rgba(5,0,5,0.8)"
      }}>
        <h2 id="parent-modal-title" className='text-center text-lg underline mb-3'>{title} (Step {modalOTP["type"] !== "exchange" ? steps - 1 : steps}) </h2>

        {steps === 1 ?
            <TransactionHandleModal modalOTP={modalOTP} /> : ''}
        
        {steps === 2 && modalOTP["type"] === "exchange" ?
            <TransactionHandleToModal modalOTP={modalOTP} /> : ''}
        
        {steps === 3 ? <TransactionHandleConfirmationModal modalOTP={modalOTP} /> : ''
        }
 
        {steps > 1 && modalOTP["type"] !== "exchange" ?
        <Button style={{    
          background: "#040E18",
          borderRadius: "70px",
          border: "2px solid #3376b3",
          color: "#fff"}} className='c1-primary-btn' onClick={handleAccept}>Confirm</Button>
          :
            steps === 3 && modalOTP["type"] === "exchange" ?

            <Button style={{    
                background: "#040E18",
                borderRadius: "70px",
                border: "2px solid #3376b3",
                color: "#fff"}} className='c1-primary-btn' onClick={handleAccept}>Confirm</Button>

                :
                <Button style={{    
                    background: "#040E18",
                    borderRadius: "70px",
                    border: "2px solid #3376b3",
                    color: "#fff"}} className='c1-primary-btn' onClick={nextStep}>Next</Button>
          }

        {steps > 1 ?
            <Button style={{    
                background: "#040E18",
                borderRadius: "70px",
                border: "2px solid #fa0a0a",
                float: "right",
                color: "#fff"}} className='c1-primary-btn' onClick={PreviousStep}>Previous</Button>
               : 
            <Button style={{    
            background: "#040E18",
            borderRadius: "70px",
            border: "2px solid #fa0a0a",
            float: "right",
            color: "#fff"}} className='c1-primary-btn' onClick={closeModal}>Close</Button>
        }
     
      </Box>
    </Modal>
  </div>
  )
}

TransactionModal.propTypes = {
    closeModal: PropTypes.func,
    modalDisplay:PropTypes.any,
    modalOTP:PropTypes.any,
    handleAccept: PropTypes.func,
    title: PropTypes.any,

}

export default TransactionModal