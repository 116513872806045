import React, {useState, useEffect} from "react";

import { UserContext } from "../../../UserContext";
import axios from 'axios'
import PropTypes from 'prop-types'

import io from "socket.io-client"

function TransactionHandleConfirmationModal({modalOTP}) {

    const{auth,user} = React.useContext(UserContext)

    const handleRef = true;

    const [amountValue, setAmountValue] = React.useState(1);
    const [otpValue, setOtpValue] = React.useState('');
    const [reference, setReferenece] = React.useState('');

    const [accountName, setAccountName] = React.useState('');
    const [bankName, setBankName] = React.useState('');
    const [accountType, setAccountType] = React.useState('');
    const [accountNumber, setAccountNumber] = React.useState('');

    const [jsonCurrencyData, setJsonCurrencyData] = useState({})
    const [exchangeFee, setExchangeFee] = useState(0)

    const [exchangeAmount, setExchangeAmount] = useState(0)

    
    
    const [exchangeObjectOptions, setExchangeObjectOptions] = React.useState({'12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0});

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
    }

    const socket = io(process.env.REACT_APP_BASE, {});
    window.socket = socket;


    const amountChange = (amount) => {
        modalOTP["amount"] = amount;
        setAmountValue(amount);


        if(modalOTP["type"] === "exchange") {
            handleExchange(amount);
        }
    };

    function handleExchange(e)
{
   
  var amountTobuy = e * exchangeObjectOptions[currencyConvert[modalOTP['from']] + currencyConvert[modalOTP['to']]]

  console.log("the amount buy is " + amountTobuy, currencyConvert[modalOTP['from']], currencyConvert[modalOTP['to']])
  var feeTotal = (amountTobuy * 3) / 100
  modalOTP['e_fee'] = feeTotal
  var totalAmountTobuy = amountTobuy - feeTotal
//   setExchangebuy2(parseFloat(totalAmountTobuy).toFixed(2))
  modalOTP['e_amount'] = parseFloat(totalAmountTobuy)
  setExchangeAmount(parseFloat(totalAmountTobuy))

  // console.log(parseFloat(e * exchangeObjectOptions[newOtpOption + newOtpOptionTo]))
  // exchangebuy1
  // console.log('check e ', jsonCurrencyData)
  // JSON.parse
  modalOTP['e_rate'] = (exchangeObjectOptions[currencyConvert[modalOTP['from']] + currencyConvert[modalOTP['to']]])
 
}

const [userBalance, setUserBalance] = useState({zar: 0, btc: 0, usdt: 0})
const [userUSDT, setUSDT] = useState(0)
const [userZAR, setZAR] = useState(0)
const [userBTC, setBTC] = useState(0)

const getUserBalance = async(userid) =>{
  var userObj = {
    userid: userid
  }

  await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-balance`,userObj).then(async (res)=>{
    if(res.data.status == 200)
    {
      console.log("the balance data is " , res.data)
      setZAR(res.data.currencyInfo[1].balance)
      setUSDT(res.data.currencyInfo[0].balance)
      setBTC(res.data.currencyInfo[2].balance)
      userBalance["zar"] = res.data.currencyInfo[1].balance
      userBalance["usdt"] = res.data.currencyInfo[0].balance
      userBalance["btc"] = res.data.currencyInfo[2].balance
    }else{
      console.log(res.data)

    }
  })

  return true;
}

React.useEffect(()=>{
    
    if(auth && user)
    {
      getUserBalance(user.user.userid)
      console.log("We should check the from stuff ", modalOTP["from"])

    }else{
        console.log("Not logged in");

    }

  },[auth])

React.useEffect(() =>{
  
    console.log('added listener');
    socket.on('currency update', onCurrencyUpdate);
    socket.emit('currency update', {});
   
  
    return () => {
      console.log('removed listener');
      socket.off('currency update', onCurrencyUpdate)
    }
  }, [])

const onCurrencyUpdate = (data) => {
    console.log('getting this one ', data)
    console.log('Custom Data', data);
    setJsonCurrencyData(data);
    console.log(data.customData.btc2usd)
    // if(typeof data.customData.btc2usd == 'undefined' || typeof data.customData.btc2zar == 'undefined' || typeof data.customData.zar2usd == 'undefined' )
    // {
    //   Swal.fire({
    //     title: 'Error!',
    //     text: 'Oops, we are unable to process the exchanges at the moment. Please try again in a couple of minutes. If this error persist, please contact support.',
    //     icon: 'error',
    //     confirmButtonText: 'Reload',
    //     confirmButtonColor: '#29a927',
    //     allowOutsideClick:false
    //   }).then((result)=>{
    //     if(result.isConfirmed){
    //       window.location.href = "/dashboard" 
    //     }
    //   })
    //   return false
    // }
  
    var btcTousdt = data.customData.btc2usd['5. Exchange Rate']
    var btcTozar = data.customData.btc2zar['5. Exchange Rate']
    var zarTousd = data.customData.zar2usd['5. Exchange Rate']
  
    console.log(parseFloat(btcTousdt), 1 /  parseFloat(btcTousdt))
    var usdTobtc = 1 / parseFloat(btcTousdt)
    var usdTozar = 1 / parseFloat(zarTousd)
    var zarTobtc = 1 / parseFloat(btcTozar)
  
  
    // '12': 0, '13': 0, '21': 0, '23': 0, '31': 0, '32': 0
    exchangeObjectOptions['12'] = parseFloat(usdTozar);
    exchangeObjectOptions['13'] = parseFloat(usdTobtc);
    exchangeObjectOptions['21'] = parseFloat(zarTousd);
    exchangeObjectOptions['23'] = parseFloat(zarTobtc);
    exchangeObjectOptions['31'] = parseFloat(btcTousdt);
    exchangeObjectOptions['32'] = parseFloat(btcTozar);
    console.log('echange data is ', exchangeObjectOptions)
  };

    const otpChange = (otp) => {
        modalOTP["otp"] = otp;
        setOtpValue(otp);
    };

    const handleReference = (reference) => {
        modalOTP["reference"] = reference;
        setReferenece(reference);
    }

    const handleBank1 = (text) => {
        setAccountName(text);
        modalOTP["reference"] = text + ' ' + bankName + ' ' + accountType + ' ' + accountNumber;
    }

    const handleBank2 = (text) => {
        setBankName(text);
        modalOTP["reference"] = accountName + ' ' + text + ' ' + accountType + ' ' + accountNumber;
    }

    const handleBank3 = (text) => {
        setAccountType(text);
        modalOTP["reference"] = accountName + ' ' + bankName + ' ' + text + ' ' + accountNumber;
    }

    const handleBank4 = (text) => {
        setAccountNumber(text);
        modalOTP["reference"] = accountName + ' ' + bankName + ' ' + accountType + ' ' + text;
    }

    const depositLabel = {
        zar: 'Reference Used',
        btc: 'TXID',
        usdt: 'TXID'
    }

    // useEffect(async res =>{
        
    //     modalOTP["reference"] = depositLabel[ modalOTP["from"]];
    //     setReferenece(depositLabel[modalOTP["from"]]);
      
    //   },[handleRef])


  return (
    <div>
    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
            {modalOTP["type"] === 'exchange' ? "Enter " + modalOTP["from"] + " Amount" : "Enter Amount"} (MAX - {modalOTP["from"] }{modalOTP["from"] === "btc" ? userBTC : modalOTP["from"] === "zar" ? userZAR : userUSDT})
        </label>
        <input type="number" min="1" start="1" value={amountValue} onChange={(e) => amountChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>

    {modalOTP["type"] === 'exchange' ?
    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" style={{ textTransform: 'uppercase'}} for="username">
            {modalOTP["to"] + " Amount"} <p className='text-sm text-red-500 underline'>3% Fee Already Deducted</p>
        </label>
        <input type="number" value={exchangeAmount} disabled className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    : ''}

    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" for="username">
            OTP
        </label>
        <input value={otpValue} onChange={(e) => otpChange(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    {modalOTP['type'] === 'deposit' ?
    <div className="mb-4">
        <label className="block text-white-700 text-sm font-bold mb-2" for="username">
            {depositLabel[ modalOTP["from"]]}
        </label>
        <input value={reference} onChange={(e) => handleReference(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

    </div>
    : 

        modalOTP["type"] !== 'exchange' ?
            <div className="mb-4">

                {modalOTP["from"] === 'zar' ? 
                <div>
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Name</label>
                    <input value={accountName} onChange={(e) => handleBank1(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Bank Name</label>
                    <input value={bankName} onChange={(e) => handleBank2(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Type</label>
                    <input value={accountType} onChange={(e) => handleBank3(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />

                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">Account Number</label>
                    <input value={accountNumber} onChange={(e) => handleBank4(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                </div>
                : 
                <div>
                    <label className="block text-white-700 text-sm font-bold mb-2" for="username">{modalOTP["from"] === 'usdt' ? 'USDT TRON ' : ''} Wallet Address</label>
                    <input value={reference} onChange={(e) => handleReference(e.target.value)} className="form-control shadow appearance-none border rounded w-full py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                </div>
                }
            </div>
        : ''
    
    }


</div>
  )
}

TransactionHandleConfirmationModal.propTypes = {
    modalOTP:PropTypes.any,
}


export default TransactionHandleConfirmationModal