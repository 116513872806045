import React, { useState } from 'react'
import {
    Chart as ChartJS,
    registerables,
  } from "chart.js";
  import { Line } from "react-chartjs-2";

  import 'chart.js/auto'

  // Icons
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

function TransactionGraph({productData}) {

  const[displayNotifications, setDisplayNotifications] =  React.useState(true);

  const toggleNotification = (toggle) => {

      setDisplayNotifications(!displayNotifications);
      console.log(displayNotifications)
  }

    var products1 = [
        {
            x: '2022-12-01',
            y: 2000
        },
        {
            x: '2022-01-01',
            y: 2500
        },
        {
            x: '2022-02-01',
            y: 1000
        }
    ]
    var products2 = [
        {
            x: '2022-11-08',
            y: 4000
        },
        {
            x: '2022-12-08',
            y: 2000
        }
    ]


    const data = {

        datasets: []
    }

    for (var i = 0; i < productData.length; i++)
    {
      var interestData = []
      for (var x = 0; x < productData[i].interests.length; x++)
      {
        var dataObj = {
          x: productData[i].interests[x].date,
          y: productData[i].interests[x].amount
        }

        interestData.push(dataObj)
      }

      if(interestData.length > 0)
      {
        console.log("Interest data is ", interestData)
        var newData = {
          label: productData[i].planid,
          data: interestData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          showLine: true
        }
  
        data.datasets.push(newData)
      }


    }
        //   {
        //     label: "#C1-44242424",
        //     data: products1,
        //     borderColor: "rgb(53, 162, 235)",
        //     backgroundColor: "rgba(53, 162, 235, 0.5)",
        //     showLine: true
        //   },
        //   {
        //     label: "#C1-454545401422",
        //     data: products2,
        //     borderColor: "#FFCE0E",
        //     backgroundColor: "#FFCE0E",
        //     borderDash: [3]
        //   }
        // ],
      // };
    
    return (
      <div  >
        <div>My Product <span className="primary-color">Interests <FontAwesomeIcon onClick={(e)=> {toggleNotification(e.target.value)}} className="pl-2" icon={faCaretDown} size="xl"  /></span></div>
        {displayNotifications ?
        <div className="mt-6">
               <Line
        // options={options}
        data={data}
        style={{vh:50}}
        datasetIdKey="id"
      />
      </div>
      : ''}
        </div>
      );
}

export default TransactionGraph