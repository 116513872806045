import React from 'react'

import AllNotifications from '../../components/Notifications/AllNotifications'


function Notifications() {

  

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">All Notifications</h1>

        <div className="container-md mx-auto px-4">

       
            <div className="container-md px-4 primary-br-2">

                <AllNotifications filter={true} showAll={true} />
                
            </div>

  

        </div>
       

    
    </div>
  )
}

export default Notifications