import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

//redux
import { useSelector, useDispatch } from "react-redux"

//components
import Header from "./Header"
import Footer from "./Footer"
import { UserContext } from '../../UserContext';
import axios from 'axios'

const Layout = (props) => {

  const{auth,user} = React.useContext(UserContext)
  const [userid, setUser] = useState('')
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])

  /*
  document title
  */
  useEffect(() => {
    const title = props.location.pathname
    let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    document.title =
      currentage + " | Dashboard"
  }, [props.location.pathname]);

  useEffect(() => {
    if(auth && user)
    {
      setUser(user.user.userid)
      // var c_object = {
      //   company: user.company
      // }
      // await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-company-products`,c_object).then((res)=>{
      //   if(res.data.status == 200)
      //   {
      //     setProducts(res.data.data)
      //   }else{
      //     setProducts(products = [
      //       {
      //         name: 'Transaction Accumulation Fund',
      //         href: '/accumulation-fund',
      //         icon: ChartBarIcon,
      //       },
      //     ])
      //   }
      // })
    }
  }, [auth]);


  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);


  /*
  layout settings
  */
  useEffect(() => {
    // dispatch(changeLayout("horizontal"));
  }, [dispatch]);


  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  }

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
      <Header user={userid}/>
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>

    </React.Fragment>
  );
}

Layout.propTypes = {
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(Layout);