import React, {useState, useEffect} from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'

function Exchange() {

    const [modalOTP, setModalOTP] = React.useState({otp: '', from: '', amount: '', type: 'exchange', reference: '', e_amount: 0, e_fee: 0, e_rate: 0});

    const[modalDisplay, setModalDisplay] =  React.useState(false);

    const{auth,user} = React.useContext(UserContext)
    const[userid, setUserid] =  React.useState('');

    React.useEffect(()=>{
    
      if (auth != null) {
        if (!auth) {
          window.location.href="/login"
        } else if(auth && user)
      {
          console.log("Logged in ", auth, user);
          setUserid(user.user.userid)
      }
    }
    },[auth])

    const currencyConvert = {
        'usdt' : '1',
        'zar' : '2',
        'btc' : '3',
    }

    const openTransactionModal = () => {

        setModalDisplay(true)
        console.log("Open the modal")
    }

    const closeModal = () => {
        setModalDisplay(false)
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
            console.log(res.data)
      if(res.data.status == 200)
      {
     
        toast.success('OTP Pin Successfully Sent!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });

        

      }else{
      
        toast.error('Unable To send OTP!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
      
    })

      }

    const handleAccept = async () => {
        console.log("submitted the following ", modalOTP)
        // setModalDisplay(false)

        if(modalOTP['amount'] <= 0)
        {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        if(modalOTP['e_amount'] <= 0)
        {
            toast.error('Unable To Create Exchange', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                return false;
        }

        var otpObj = {
            userid: userid,
            otp: modalOTP['otp']
          }
          
        const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
                  
            if(res.data.status !== 200)
            {

                toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    await sendOTP();

                return false;
            }else{
                return true;
            }
        });

        if(!checkOtp)
            return false;

        
            var balanceObject = {
                userid: userid,
                currency: currencyConvert[modalOTP['from']]
              }
    
         
    
          
            var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL+"/user-balance",balanceObject).then(async res =>{
          
              console.log('set the balance to ', res.data)
              if(parseFloat(res.data.total) < modalOTP['amount'])
              {
                toast.error('Insufficient Balance!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });
    
                    return false;
              }else{
                return true
              }
           
            })

            if(!checkBalance)
              return false;

            var createExchange = await exchangeAccept();
      }

      const exchangeAccept = async () => {

        const exchangeObject = {
            userid: userid,
            excahnge_type: '1',
            amount_from: currencyConvert[modalOTP['from']],
            amount_to: currencyConvert[modalOTP['to']],
            status: '0',
            amount: parseFloat(modalOTP['e_amount']),
            from_amount: parseFloat(modalOTP['amount']).toFixed(2),
            exchange_rate: parseFloat(modalOTP['e_rate']).toFixed(2),
            exchange_amount: modalOTP['e_amount'] + modalOTP['e_fee'],
            fee: modalOTP['e_fee'],
          }

       

      await axios.post(process.env.REACT_APP_BASE + "api/create-exchange",exchangeObject).then(async res2 =>{

          if(res2.data.status == 200)
          {
            toast.success('Exchange Created Successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                setTimeout(() => {window.location.reload(); }, 2000);
          }else{
            toast.error('Unable To Create Exchange', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
          }

      })
      }

  return (
    <div className="container mx-auto px-2 pt-8 pb-8">

        {modalDisplay ? 
            <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} title="Exchange" />
            : ''
        }
        <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Exchange</h1>

        <div className="container-md mx-auto px-4">

       
            <div className="container-md px-4 primary-br-2">

                <div className="">
                <ol type="1">
                        <li>Exchanges can be made between currencies (ZAR, USDT & Bitcoin) at a fee of 3% which will automatically be calculated and deducted when the exchange is processed.</li>
                        <li>The exchange will remain pending until approved by Nessfin Admin which can take up to 24 hours.</li>

                    </ol>  

                </div>

                <div className='m-auto text-center'><button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center'>Exchange Now</button></div>
                
            </div>

  

        </div>
       

    
    </div>
  )
}

export default Exchange