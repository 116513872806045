import React from "react"
import ReactDOM from "react-dom/client";
import "./styles/main.css"
import "./assets/css/theme.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom"

import { Provider } from "react-redux"
import 'tw-elements';

import store from "./store"


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
     

      <App />
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
