import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  shouldBeAuth,
  ...rest
}

) => (
  
  <Route
    {...rest}
    render={props => {

      console.log("Checking for Auth middleware ", isAuthProtected, shouldBeAuth)
      if(shouldBeAuth && !isAuthProtected)
      {
        // window.location.href = "/login"
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any, 
  shouldBeAuth: PropTypes.bool,
}

export default Authmiddleware;
